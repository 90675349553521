import {
    CREATE_ACCOUNT_PATH,
    CREATE_PASSWORD_PATH,
    MYX_ACTIVATE_BIKE_PATH,
    MYX_ACTIVATION_SUCCESS,
    MYX_CHECKOUT_PATH,
    MYX_CREATE_ACCOUNT_PATH,
    MYX_FORGOT_PASSWORD_PATH,
    MYX_LOGIN_PATH,
    MYX_ORDER_CONFIRMATION_PATH,
    MYX_SESSION_EXPIRED_PATH,
    MYX_RESET_PASSWORD_PATH,
    MYX_EDIT_EMAIL_PATH,
    BIKES_PDP_PATH,
    INTERSTITIAL_PAGE,
    COACH_UPSELL_CONFIRMATION,
    COACH_NO_UPSELL_CONFIRMATION,
    COACH_OPT_IN_CONFIRMATION,
    CHECKOUT_PATH,
    CONFIRMATION_PASSWORD_PATH,
} from "../../constants/urls";

export const AuthorizedPaths = [
    CHECKOUT_PATH,
    "confirmation",
    "offers",
    "reset-password",
    "survey",
    COACH_UPSELL_CONFIRMATION,
    COACH_NO_UPSELL_CONFIRMATION,
    COACH_OPT_IN_CONFIRMATION,
    CONFIRMATION_PASSWORD_PATH,
    INTERSTITIAL_PAGE,
    CREATE_ACCOUNT_PATH,
    CREATE_PASSWORD_PATH,
    MYX_ACTIVATE_BIKE_PATH,
    MYX_CHECKOUT_PATH,
    MYX_CREATE_ACCOUNT_PATH,
    MYX_FORGOT_PASSWORD_PATH,
    MYX_LOGIN_PATH,
    MYX_ORDER_CONFIRMATION_PATH,
    MYX_SESSION_EXPIRED_PATH,
    MYX_RESET_PASSWORD_PATH,
    MYX_ACTIVATION_SUCCESS,
    MYX_EDIT_EMAIL_PATH,
    BIKES_PDP_PATH,
];

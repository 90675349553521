/* eslint-disable react/prop-types */
import React, { useEffect, useState, Fragment, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Loader } from "../../common";
import { checkoutActions } from "./data/checkout-page-action";
import { activationActions } from "../activation-page/data/activation-page-action";
import { offersActions } from "../offers-page/data/offers-page-action";
import { pageRouterActions } from "../../component/page-router/data/page-router-action";
import { getProductDetails } from "../../helper/util";
import { cardErrorMessage } from "./constants";
import {
    MyxUpdateCreditCardModal,
    MyxCreditCardDetails,
    MyxCoachRefferalSection,
    ErrorPanel,
    MyxMembershipAcceptance,
    OrderSummaryCard,
    MyxProductInfoSection,
} from "./components";

import { FieldRenderer } from "../../contentful/component-renderer";
import { ExpressCheckout, CheckoutFormErrors, CheckoutForm, ZIPDetails } from "../../model";
import { validateForm, validateField } from "../common/validator";
import { CaptchaEmitter, getCookie, intializeGoogleCaptchaV3, setCookie, TRIGGER_CAPTCHA } from "../../helper/util";
import { MyxSecurityContent } from "./components/security-content/myx-security-content";
import { MyxCheckoutAction } from "./myx-checkout-action-panel";
import { myxCreateAccountPageUrl, activateBikePageUrl, redirectWithParam } from "../common/util";
import { MYX_ORDER_CONFIRMATION_PATH } from "../../constants/urls";
import { DefaultCreditCard } from "../../model/cart-details/default-creditcard";
import { logCustomEvents } from "../../web-analytics";
import * as tealiumTags from "../../constants/tealium-tags";

export const MyxCheckoutPageContent = ({ contents, commonFooter, t, ...props }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { enableFusion, enableOneBodi, paypalEnabled, isCVViFrame } = useSelector((state) => state.checkout);
    const { userDetail } = useSelector((state) => state.pageRouter);
    const { activationCode } = useSelector((state) => state.activation);
    const coachRefferalRef = useRef(null);
    const [isFetchingCoachDetails, setIsFetchingCoachDetails] = useState(false);
    const paypalFeatureFlagEnabled = paypalEnabled === "true" || paypalEnabled === true;

    const prepopulateUserData = (order, abondonedUserData) => {
        const { email, password, firstName, lastName, guid, accessToken } = userDetail || {};
        let userData = { email, password, firstName, lastName, guid, accessToken };
        userData = order && order.addressDetails ? { ...userData, ...order.addressDetails } : userData;
        userData = abondonedUserData && abondonedUserData.profile ? { ...userData, ...abondonedUserData.profile } : userData;
        return userData;
    };
    const { email } = userDetail || {};
    const { fields } = contents;
    const {
        securityContent,
        userAcceptanceCheckboxLabel,
        showCoachQuestion,
        sectionTitles,
        productInfo,
        ctaButton,
        coachDropdownQuestion,
        coachNoAnswer,
        coachYesAnswer,
        coachYesAnswerText,
        coachNoAnswerText,
        coachSubTitle,
        coachSubTitleOneBODi,
        defaultSelectedOffer,
        textContent,
    } = fields;
    const buttonText = ctaButton && ctaButton.fields && ctaButton.fields.buttonText ? ctaButton.fields.buttonText : null;
    const getSectionTitle = (id) => {
        const objects = sectionTitles.filter((sectionTitle) => {
            const fields = sectionTitle.fields;
            return fields.id === id;
        });
        const object = objects && Array.isArray(objects) ? objects[0] : null;
        const result =
            object && object.fields
                ? enableOneBodi && object.fields?.sectionTitleOneBODi
                    ? object.fields.sectionTitleOneBODi
                    : object.fields.sectionTitle
                : "";
        return result || "";
    };
    const membershipSectionTitle = getSectionTitle("membership");
    const paymentSectionTitle = getSectionTitle("payment");
    const coachSectionTitle = getSectionTitle("coach");
    const termsSectionTitle = getSectionTitle("terms");

    const isCheckoutLandscape = useSelector((state) => state.pageRouter.isCheckoutLandscape);
    const { order, sessionId, isLoading } = useSelector((state) => state.offers);
    const abondonedUserData = {};
    const { zipCodeDetails, cyberSource, loadPage, defaultCreditCard } = useSelector((state) => state.checkout);
    const { formErrors } = useSelector((state) => state.checkout);
    const [expressCheckout, setExpressCheckout] = useState(new ExpressCheckout({ ...prepopulateUserData(order, abondonedUserData) }));
    const expressCheckoutRef = useRef(expressCheckout);
    const [checkoutFormErrors, setCheckoutFormErrors] = useState(new CheckoutFormErrors());
    const [doFieldValidation, triggerFieldValidators] = useState(false);
    const [btnDisable, setBtnDisable] = useState(false);
    const [isShowUpdateCCModal, setShowUpdateCCModal] = useState(false);
    const [isZipCodeFailed, setZipCodeFailed] = useState(false);
    const [isZipCodeLookUpFailed, setZipCodeLookUpFailed] = useState(false);
    const [zipCodeLookUpError, setZipCodeLookupError] = useState();
    const [isFormValidated, setIsFormValidated] = useState(false);
    const [apiFormErrors, setAPIFormErrors] = useState(true);
    const [APIFormErrMsgs, setAPIFormErrMsgs] = useState();
    const [submitForm, setSubmitForm] = useState(null);
    const [isPaypalDefault, setIsPaypalDefault] = useState(false);
    const [isCreditCardSelected, setIsCreditCardSelected] = useState(false);
    const [coachOptInAnalytics, setCoachOptInAnalytics] = useState();
    const securityContentModule = <MyxSecurityContent fields={securityContent.fields} />;
    const isCreditCardError = checkoutFormErrors && checkoutFormErrors["FailedCreditCardAuth"];
    const isCreditCardExpired = checkoutFormErrors && checkoutFormErrors["ccExpiry"];
    const isBODUser = userDetail && userDetail.CustomerType && userDetail.CustomerType === "club";
    const assignedCoachType = order && order?.profile?.assignedCoachType;
    const isAssignedCorporateCoach = assignedCoachType && assignedCoachType === "CORPORATE";
    const isPCUser = userDetail && userDetail.customerType.split(",").includes("PC");

    const setCheckoutData = (newData) => {
        expressCheckoutRef.current = newData;
        setExpressCheckout(newData);
    };

    const checkboxes =
        order && order.pageComponent && Array.isArray(order.pageComponent.checkboxes)
            ? order.pageComponent.checkboxes.filter((checkbox) => !checkbox.checkServiceURL)
            : [];

    const onBikeActivationSuccess = () => {
        logUserEvents();
        window.scroll(0, 0);
        redirectWithParam(history, MYX_ORDER_CONFIRMATION_PATH.substring(1));
    };
    const logUserEvents = () => {
        logCustomEvents(tealiumTags.coachoptinselection, tealiumTags.coachoptinselection, coachOptInAnalytics, true);
    };
    const doUserLogin = () => {
        const { email, password } = userDetail || {};
        dispatch({
            type: checkoutActions.USER_LOGIN,
            payload: { email, password },
            callbacks: { success: doActivateBike, failure: onBikeActivationSuccess },
        });
    };
    const doActivateBike = (userInfo = {}) => {
        userInfo = { ...userDetail, ...userInfo };
        const { guid, accessToken } = userInfo || {};
        const activationCode = localStorage.getItem("activationCode") || "";
        if (accessToken && guid) {
            !userDetail?.guid &&
                dispatch({
                    type: pageRouterActions.SET_USER_DETAILS,
                    payload: { ...userDetail, guid },
                });
            dispatch({
                type: checkoutActions.ACTIVATE_MYX_BIKE,
                payload: {
                    accessToken: accessToken,
                    activationCode: activationCode,
                    profileId: guid,
                    isOAuth: true,
                },
                callbacks: { success: onBikeActivationSuccess, failure: onBikeActivationSuccess },
            });
        } else doUserLogin();
    };
    const validateActivationCode = () => {
        dispatch({
            type: activationActions.VERIFY_ACTIVATION_CODE,
            payload: { activationCode: activationCode },
            callbacks: { success: userDetail && userDetail.isNewUser ? doUserLogin : doActivateBike, failure: doActivateBike },
        });
    };
    const onSuccessOfPlaceOrder = () => {
        validateActivationCode();
        setCheckoutData({ ...expressCheckout, ccCVV: "", ccNumber: "", ccExpDate: "", ccMonth: "", ccYear: "", ccType: "" });
    };
    const onFailureOfPlaceOrder = () => {
        setBtnDisable(false);
    };
    const updateZipCodeStatus = (STATUS) => {
        setBtnDisable(false);
        if (STATUS === "FAILED") {
            setZipCodeFailed(true);
        } else if (STATUS === "LOOKUP_FAILED") {
            setZipCodeLookUpFailed(true);
        } else if (STATUS === "SUCCESS") {
            setZipCodeFailed(false);
            setZipCodeLookUpFailed(false);
        }
    };
    const cartItem = order && order.commerceItems && order.commerceItems.length && order.commerceItems[0];
    const reDoZipCodeAPICallBack = () => {
        const { shippingPostalCode } = expressCheckout || {};
        const shippable = cartItem && cartItem.isShippable;
        shippable &&
            dispatch({
                type: checkoutActions.GET_ZIPCODE_DETAILS,
                payload: shippingPostalCode,
                zipCodeName: "shippingPostalCode",
                isToCalculateTax: shippable,
            });
    };
    const logErrorAnalytics = (errors: any) => {
        const result = Object.values(errors).filter((error) => error);
        logCustomEvents(tealiumTags.myx_error_message, tealiumTags.myx_error_message, result, true);
    };
    const validateFormErrors = () => {
        const fieldsToValidate = (order && order.validateUserConfirmations) || [];
        const hasDefaultCC = defaultCreditCard && defaultCreditCard.ccNumber ? true : false;
        const validateFields = [...fieldsToValidate, "billingFirstName", "billingLastName", "coachId"];
        const errors = validateForm({ ...expressCheckout, isMyXFlow: true }, t, userDetail.isExistingFreeUser, validateFields, hasDefaultCC, isCVViFrame);
        errors && logErrorAnalytics(errors);
        const { shippingPostalCode, billingPostalCode } = zipCodeDetails || {};
        const shippable = cartItem && cartItem.isShippable;
        const shippingCode = shippingPostalCode && shippingPostalCode["STATUS"] === "FAILED";
        const billingCode = billingPostalCode && billingPostalCode["STATUS"] === "FAILED";
        const zipCodeErrors = {};
        if (shippable && shippingCode) {
            zipCodeErrors["shippingPostalCode"] = t("zip-err-msg");
        }
        if (billingCode) {
            zipCodeErrors["billingPostalCode"] = t("zip-err-msg");
        }
        setIsFormValidated(true);
        if (errors) {
            // console.log("Errors: ", errors);
            setCheckoutFormErrors({ ...checkoutFormErrors, ...errors, ...zipCodeErrors });
            return true;
        } else if (Object.keys(zipCodeErrors).length) {
            setCheckoutFormErrors({ ...checkoutFormErrors, ...zipCodeErrors });
            return true;
        } else {
            setCheckoutFormErrors(new CheckoutFormErrors());
            return false;
        }
    };
    const setFormErrors = (name, value, multiple) => {
        if (multiple && typeof multiple === "object") {
            //Please pass the translated error message
            setCheckoutFormErrors({ ...checkoutFormErrors, ...multiple });
        } else {
            setCheckoutFormErrors({ ...checkoutFormErrors, [name]: value ? t(value) : "" });
        }
    };
    const updateZipCodeErrors = () => {
        const { shippingPostalCode, billingPostalCode } = zipCodeDetails || {};
        const shippable = cartItem && cartItem.isShippable;
        const shippingCode = shippingPostalCode && shippingPostalCode["STATUS"] === "FAILED";
        const billingCode = billingPostalCode && billingPostalCode["STATUS"] === "FAILED";
        shippable && shippingCode && !checkoutFormErrors["shippingPostalCode"] && setFormErrors("shippingPostalCode", "zip-err-msg");
        billingCode && !checkoutFormErrors["billingPostalCode"] && setFormErrors("billingPostalCode", "zip-err-msg");
        const isError = shippable ? shippingCode || billingCode : billingCode;
        if (isError) {
            setZipCodeFailed(true);
        } else {
            setZipCodeFailed(false);
        }
        return isError;
    };
    const doExpressCheckout = (captcha) => {
        const webSourceCode = getCookie("webSourceCode");
        const analyticsData = {
            webSourceCode: webSourceCode ? webSourceCode + "*|*" : "",
            ...captcha,
        };
        const bypassCaptcha = sessionStorage.getItem("bypassCaptcha");
        const expressCheckoutData = {
            ...expressCheckout,
            bypassCaptcha: bypassCaptcha ? bypassCaptcha : null,
        };
        const coachDDAnswer = JSON.parse(sessionStorage.getItem("coachDDAnswer"));
        if (coachDDAnswer === "") {
            delete expressCheckoutData["coachConsent"];
        }
        const { guid, accessToken } = userDetail;
        dispatch({
            type: checkoutActions.PLACE_MYX_CHECKOUT_ORDER,
            payload: {
                activationCode: activationCode,
                profileId: guid,
                accessToken: accessToken,
                expressCheckout: new CheckoutForm({ ...expressCheckoutData, ...analyticsData }),
            },
            callbacks: { success: onSuccessOfPlaceOrder, failure: onFailureOfPlaceOrder },
        });
    };
    const verifyCoachIdField = () => {
        if (isFetchingCoachDetails) {
            if (coachRefferalRef?.current) {
                coachRefferalRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
                return false;
            }
        }
        return true;
    };
    const onPlaceOrder = () => {
        function placeOrder() {
            setBtnDisable(true);
            setZipCodeLookupError(null);
            const bypassCaptcha = sessionStorage.getItem("bypassCaptcha");
            const gCaptcha = getCookie("g-captcha-data");
            if ((bypassCaptcha === "true" || gCaptcha) && !apiFormErrors) {
                const bypassCaptchaToken = bypassCaptcha === "true" ? { bypassCaptcha: true } : {};
                doExpressCheckout(bypassCaptchaToken);
            } else {
                intializeGoogleCaptchaV3();
            }
        }

        if (isPaypalDefault && !isCreditCardSelected) {
            placeOrder();
        } else {
            setSubmitForm(null);
            if (!isZipCodeLookUpFailed && !validateFormErrors() && !updateZipCodeErrors() && verifyCoachIdField()) {
                placeOrder();
            } else {
                setBtnDisable(false);
                triggerFieldValidators(true);
            }
            if (isZipCodeLookUpFailed) {
                reDoZipCodeAPICall();
            }
        }
    };
    useEffect(() => {
        if (zipCodeLookUpError === false && btnDisable) {
            onPlaceOrder();
        } else if (zipCodeLookUpError) {
            setBtnDisable(false);
        }
    }, [zipCodeLookUpError]);
    const reDoZipCodeAPICall = () => {
        setBtnDisable(true);
        const { billingPostalCode } = expressCheckout || {};
        const shippable = cartItem && cartItem.isShippable;
        billingPostalCode &&
            dispatch({
                type: checkoutActions.GET_ZIPCODE_DETAILS,
                payload: billingPostalCode,
                zipCodeName: "billingPostalCode",
                isToCalculateTax: !shippable,
                callbacks: { success: reDoZipCodeAPICallBack, failure: reDoZipCodeAPICallBack },
            });
    };
    const { isShippable } = cartItem || {};
    const postalCodeKey = isShippable ? "shippingPostalCode" : "billingPostalCode";
    window.digitalData.order = order ? order : {};
    const updateExpressCheckout = (result) => {
        const { isToCalculateTax } = result || {};
        const billing = isToCalculateTax ? !isShippable && isToCalculateTax : true;
        const postalCodeKey = billing ? "billingPostalCode" : "shippingPostalCode";
        Object.keys(result).map((key) => {
            if (result[key]["STATUS"]) {
                updateZipCodeStatus(result[key]["STATUS"]);
                const data = result[key];
                const { STATUS } = data || {};
                const details = { billing: billing, billShipSame: !isShippable, zipcode: expressCheckout[postalCodeKey] };
                if (!billing) {
                    //Override the city and state fields with user enterd values
                    details["city"] = expressCheckout["shippingCity"];
                    details["state"] = expressCheckout["shippingState"];
                }
                STATUS === "SUCCESS" || STATUS === "LOOKUP_FAILED" ? setFormErrors(postalCodeKey, "") : setFormErrors(postalCodeKey, "zip-err-msg");
                if (STATUS === "SUCCESS") {
                    const zipDetails = new ZIPDetails({ ...result[postalCodeKey], ...details });
                    setCheckoutData({ ...expressCheckout, ...zipDetails });
                }
            }
        });
    };
    useEffect(() => {
        if (zipCodeDetails) {
            const { billingPostalCode, shippingPostalCode } = zipCodeDetails || {};
            if (
                doFieldValidation &&
                ((billingPostalCode && billingPostalCode["STATUS"] === "LOOKUP_FAILED") ||
                    (shippingPostalCode && shippingPostalCode["STATUS"] === "LOOKUP_FAILED"))
            ) {
                setZipCodeLookupError(true);
            } else {
                doFieldValidation && setZipCodeLookupError(false);
            }
            updateExpressCheckout(zipCodeDetails);
            updateZipCodeErrors();
        }
    }, [zipCodeDetails]);
    useEffect(() => {
        setAPIFormErrors(false);
        if (formErrors) {
            const { formExceptions } = formErrors || {};
            setAPIFormErrMsgs(formExceptions);
            setAPIFormErrors(true);
        }
    }, [formErrors]);
    useEffect(() => {
        if (APIFormErrMsgs) {
            const errors = {};
            APIFormErrMsgs.length > 0 &&
                APIFormErrMsgs.map((error) => {
                    return (errors[error?.errorCode] = error?.localizedMessage);
                });
            logErrorAnalytics(errors);
        }
    }, [APIFormErrMsgs]);
    const calculateTax = ({ name, value, doCalculateTax = false }) => {
        try {
            const error = validateField({ name, value });
            const { shippingPostalCode, billingPostalCode } = expressCheckout || {};
            const shippable = cartItem && cartItem.isShippable;
            let postalCode = shippable ? shippingPostalCode : billingPostalCode;
            postalCode = doCalculateTax ? value : postalCode;
            if (error) {
                setFormErrors(name, error);
            } else if (postalCode && postalCodeKey === name) {
                setFormErrors(name, "");
                dispatch({
                    type: checkoutActions.GET_ZIPCODE_DETAILS,
                    payload: postalCode,
                    zipCodeName: name,
                    isToCalculateTax: true,
                });
            } else if (billingPostalCode) {
                dispatch({
                    type: checkoutActions.GET_ZIPCODE_DETAILS,
                    payload: billingPostalCode,
                    zipCodeName: name,
                    isToCalculateTax: false,
                });
            }
        } catch (error) {
            console.error("Error at calculateTax method", error);
        }
    };
    const onChange = (name, value, fieldValues) => {
        if (name === "ccNumber" || (fieldValues && fieldValues.ccNumber)) {
            const errors =
                APIFormErrMsgs &&
                APIFormErrMsgs.filter((error) => {
                    const { errorCode } = error || {};
                    return !(errorCode && errorCode.startsWith("FailedCreditCardAuth"));
                });
            APIFormErrMsgs && setAPIFormErrMsgs(errors);
            checkoutFormErrors && checkoutFormErrors[name] && setFormErrors(name, "");
        }
        if (name === "ccCVV" && isCVViFrame) {
            const { empty, valid, couldBeValid } = value || {};
            if (!couldBeValid && !valid) {
                setFormErrors("ccCVV", t("cvv-err-msg"), "");
            } else if (empty) {
                setFormErrors("ccCVV", t("cvv-err-msg-req"), "");
            } else if (valid && couldBeValid) {
                setFormErrors("ccCVV", "", "");
            }
            setCheckoutData({ ...expressCheckout, isCVVValid: (!empty && couldBeValid && valid) ?? false });
            return;
        }
        if (name === "shippingPostalCode" || name === "billingPostalCode") {
            value = value && value.toUpperCase();
        }
        if (fieldValues && typeof fieldValues === "object") {
            const expressData = { ...expressCheckout, ...fieldValues };
            setCheckoutData(expressData);
        } else {
            setCheckoutData({ ...expressCheckout, [name]: value });
        }
    };
    const onCartDetailsFetchSuccess = (order) => {
        console.log("Order: ", order);
    };
    const onCartDetailFetchFailure = () => {
        const url = activateBikePageUrl(email);
        props && props.history && props.history.push(url);
    };
    const refreshCreditCardInfo = () => {
        setCheckoutFormErrors(new CheckoutFormErrors());
        const { billingPostalCode } = defaultCreditCard || {};
        setCheckoutData({ ...expressCheckout, ...defaultCreditCard, shippingPostalCode: billingPostalCode });
        billingPostalCode && calculateTax({ name: "billingPostalCode", value: billingPostalCode, doCalculateTax: true });
    };
    useEffect(() => {
        if (isZipCodeFailed) {
            updateZipCodeErrors();
        }
        if (isFormValidated) {
            const element = document.querySelector(".error-msg");
            element && element.scrollIntoView({ behavior: "smooth", block: "center" });
            setIsFormValidated(false);
        }
    }, [checkoutFormErrors]);

    useEffect(() => {
        if (sessionId) {
            if (!order) {
                dispatch({
                    type: offersActions.GET_CART_DETAILS,
                    callbacks: { success: onCartDetailsFetchSuccess, failure: onCartDetailFetchFailure },
                });
            }
        }
    }, [sessionId]);
    useEffect(() => {
        cyberSource && sessionId && dispatch({ type: checkoutActions.GET_CYBERSOURCE_KEY });
        cyberSource === false ? dispatch({ type: checkoutActions.LOAD_PAGE, payload: true }) : null;
    }, [cyberSource]);
    useEffect(() => {
        window.scroll(0, 0);
        const activationCode = localStorage.getItem("activationCode");
        if (!userDetail) {
            const redirectUrl = myxCreateAccountPageUrl();
            props && props.history && props.history.push(redirectUrl);
        }
        if (!activationCode) {
            const redirectUrl = activateBikePageUrl(email);
            props && props.history && props.history.push(redirectUrl);
        }
        const date = new Date();
        date.setTime(date.getTime() + 115 * 1000);
        setCookie("g-captcha-data", "false", date);
        CaptchaEmitter.addListener(TRIGGER_CAPTCHA, (data) => {
            setSubmitForm(data);
            setAPIFormErrors(false);
        });
        intializeGoogleCaptchaV3();
    }, []);
    useEffect(() => {
        if (submitForm) {
            const { submitForm: isToPlaceOrder, "g-recaptcha-response": captcha } = submitForm || {};
            onChange("g-recaptcha-response", captcha);
            isToPlaceOrder && doExpressCheckout({ "g-recaptcha-response": captcha });
        }
    }, [submitForm]);
    const formProps = {
        t,
        onChange,
        order,
        setFormErrors,
        formErrors: checkoutFormErrors,
        formData: expressCheckout,
        setCheckoutData,
        validateField,
        validateFormErrors,
        doFieldValidation,
        calculateTax,
        cartItem,
        onCartDetailsFetchSuccess,
        onCartDetailFetchFailure,
        dispatch,
        setIsPaypalDefault,
        isPaypalDefault,
        setIsCreditCardSelected,
        isCreditCardSelected,
        prepopulateUserData,
        refreshCreditCardInfo,
    };
    useEffect(() => {
        order &&
            order.addressDetails &&
            setCheckoutData({
                ...expressCheckout,
                ...order.addressDetails,
            });
    }, [order]);
    useEffect(() => {
        if (defaultCreditCard && !defaultCreditCard.isNewCardSaved && !isCreditCardSelected) {
            const { billingPostalCode } = defaultCreditCard || {};
            setCheckoutData({
                ...expressCheckoutRef.current,
                ...defaultCreditCard,
                ...(defaultCreditCard.ccType === "PAYPAL" && {
                    paymentMethod: "paypalBraintree",
                    paymentMethodAccount: defaultCreditCard?.ccNumber,
                    paymentMethodToken: defaultCreditCard?.token,
                    ccNumber: "",
                    ccMonth: "",
                    ccYear: "",
                    ccExpDate: "",
                    ccCVV: "",
                    ccType: "",
                }),
            });
            if (defaultCreditCard.ccType === "PAYPAL") {
                setIsPaypalDefault(true);
                if (!paypalFeatureFlagEnabled) {
                    setIsCreditCardSelected(true);
                    return;
                }
            }
            billingPostalCode && calculateTax({ name: "billingPostalCode", value: billingPostalCode, doCalculateTax: true });
        }
    }, [defaultCreditCard, isCreditCardSelected]);
    useEffect(() => {
        if (isShowUpdateCCModal || isCreditCardSelected) {
            setCheckoutFormErrors(new CheckoutFormErrors());
            const defaultCCDetails = new DefaultCreditCard({});
            setCheckoutData({
                ...expressCheckout,
                ...defaultCCDetails,
                ...(defaultCreditCard?.billingPostalCode && {
                    billingPostalCode: defaultCreditCard?.billingPostalCode,
                }),
                paymentMethod: "",
                paymentMethodAccount: "",
                paymentMethodToken: "",
            });
        }
    }, [isShowUpdateCCModal, isCreditCardSelected]);
    useEffect(() => {
        enableOneBodi && getProductDetails(userDetail, dispatch, true);
    }, [dispatch, enableOneBodi]);
    return (
        <Fragment>
            {!isLoading && sessionId && (cyberSource ? loadPage : loadPage) ? (
                <section className="myx-checkout-page container">
                    <Fragment>
                        {order && !isShowUpdateCCModal ? (
                            <div className="myx-container w-100">
                                <div className="myx-panel">
                                    <MyxProductInfoSection
                                        {...formProps}
                                        contents={productInfo}
                                        defaultSelectedOffer={defaultSelectedOffer}
                                        formattedPrice={order.priceInfo && order.priceInfo.rawSubtotal}
                                        sectionTitle={membershipSectionTitle}
                                        enableOneBodi={enableOneBodi}
                                        t={t}
                                    />
                                    <div id="credit-card-details" className="checkout-section">
                                        <MyxCreditCardDetails
                                            {...formProps}
                                            sectionTitle={paymentSectionTitle}
                                            defaultCreditCard={defaultCreditCard}
                                            setCheckoutData={setCheckoutData}
                                            showSummary={
                                                defaultCreditCard &&
                                                defaultCreditCard.creditCardNumber !== "" &&
                                                (defaultCreditCard.ccType === "PAYPAL" ? paypalFeatureFlagEnabled : true)
                                            }
                                            onShowUpdateCCModal={() => setShowUpdateCCModal(!isShowUpdateCCModal)}
                                        />
                                    </div>
                                    {!isBODUser && showCoachQuestion && (userDetail.isNewUser || (!isPCUser && isAssignedCorporateCoach && enableFusion)) && (
                                        <MyxCoachRefferalSection
                                            formProps={formProps}
                                            abondonedUserData={abondonedUserData}
                                            sectionTitle={coachSectionTitle}
                                            checkboxes={order?.pageComponent?.checkboxes}
                                            coachYesAnswer={coachYesAnswer}
                                            coachNoAnswer={coachNoAnswer}
                                            coachDropdownQuestion={coachDropdownQuestion}
                                            coachYesAnswerText={coachYesAnswerText}
                                            coachNoAnswerText={coachNoAnswerText}
                                            coachSubTitle={coachSubTitle}
                                            setCoachOptInAnalytics={setCoachOptInAnalytics}
                                            coachSubTitleOneBODi={coachSubTitleOneBODi}
                                            enableOneBodi={enableOneBodi}
                                            coachRefferalRef={coachRefferalRef}
                                            setIsFetchingCoachDetails={setIsFetchingCoachDetails}
                                            textContent={textContent}
                                        />
                                    )}
                                    <div className="d-flex align-items-center justify-content-center mt-4">
                                        <OrderSummaryCard t={t} order={order} enableOneBodi={enableOneBodi} {...props} />
                                    </div>
                                    <div className="myx-checkboxes">
                                        <MyxMembershipAcceptance
                                            {...formProps}
                                            checkboxes={checkboxes}
                                            content={userAcceptanceCheckboxLabel.content}
                                            sectionTitle={termsSectionTitle}
                                        />
                                    </div>
                                    {isCreditCardError && <ErrorPanel errorMessage={cardErrorMessage} t={t} />}
                                    {!isCreditCardError && isCreditCardExpired && defaultCreditCard && defaultCreditCard.ccNumber !== "" && (
                                        <ErrorPanel errorMessage={t("card-expired")} t={t} />
                                    )}
                                    <MyxCheckoutAction
                                        t={t}
                                        zipCodeLookUpError={zipCodeLookUpError}
                                        apiFormErrors={apiFormErrors}
                                        APIFormErrMsgs={APIFormErrMsgs}
                                        doFieldValidation={doFieldValidation}
                                        isCheckoutLandscape={isCheckoutLandscape}
                                        btnDisable={btnDisable}
                                        onPlaceOrder={onPlaceOrder}
                                        expressCheckout={expressCheckout}
                                        securityContentModule={securityContentModule}
                                        buttonText={isPaypalDefault && !isCreditCardSelected ? t("pay-with") : buttonText}
                                        fieldsToValidate={order && order.validateUserConfirmations}
                                        isPaypalDefault={isPaypalDefault}
                                        isCreditCardSelected={isCreditCardSelected}
                                    />
                                    {!isCheckoutLandscape ? securityContentModule : null}
                                    <FieldRenderer contents={commonFooter} />
                                </div>
                            </div>
                        ) : (
                            <div className="myx-container w-100">
                                <div className="myx-panel update-cc-details">
                                    <MyxUpdateCreditCardModal
                                        {...formProps}
                                        sectionTitle={paymentSectionTitle}
                                        defaultCreditCard={defaultCreditCard}
                                        doFieldValidation={doFieldValidation}
                                        showSummary={false}
                                        onShowUpdateCCModal={() => setShowUpdateCCModal(!isShowUpdateCCModal)}
                                    />
                                </div>
                            </div>
                        )}
                    </Fragment>
                </section>
            ) : (
                <section className="myx-loader container">
                    <div className="myx-container w-100">
                        <div className="myx-panel">
                            <Loader className="loader-wrapper" isToShowLoader={true} />
                        </div>
                    </div>
                </section>
            )}
        </Fragment>
    );
};
MyxCheckoutPageContent.propTypes = {
    contents: PropTypes.object,
    commonFooter: PropTypes.object,
    props: PropTypes.object,
};

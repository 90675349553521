import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { AppRouter } from "./app/app-router";
import configureStore from "./configureStore";
import "./i18n/i18n";
import "./styles/index.scss";

const store = configureStore();

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <AppRouter />
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

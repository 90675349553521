import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { AuthorizedPaths } from "./page-config";
import { pageRouterActions } from "../page-router/data/page-router-action";
import {
    INTERSTITIAL_PAGE,
    MYX_CREATE_ACCOUNT_PATH,
    MYX_RESET_PASSWORD_PATH,
    CREATE_ACCOUNT_PATH,
    CHECKOUT_PATH,
    REGION_CONFLICT_POPUP,
} from "../../constants/urls";
import { FieldRenderer, PageTemplateRenderer } from "../../contentful/component-renderer";
import { checkForCaptchaByPass, getCookie, getOffersPageId, getUrlParam, renderBaseName, setCookie, setOffersPageId, decodeURIHelper } from "../../helper/util";
import { SessionTimeOutModal } from "../../module/session-timeout/session-time-out";
import { createDigitalData, logDigitalData } from "../../web-analytics";
import { redirectWithParam } from "../../module/common/util";
import { createAccount, checkout } from "../../module/create-account/components/constants";
import { PopupEmitter, TRIGGER_POPUP } from "../../common/popup-emitter";
import { countryCodeForDomain } from "../../constants/app-languages";
import { defaultLanguages } from "../../helper/language-supported";
import { datadogRum } from "@datadog/browser-rum";

export const BDDBasePage = (props) => {
    let path = props.location.pathname;
    const { basePageCSS, locale, page, pageId, pageTemplateName, userDetail, interstitialPage, regionConflictPopup, user_country } = useSelector(
        (state) => state.pageRouter,
    );
    const { order } = useSelector((state) => state.offers);
    const contentIdValue = window.$contentIdValue;
    const currentLocation = useLocation();
    const digitalData = useSelector((state) => state.pageRouter.digitalData);
    const dispatch = useDispatch();
    const refRepId = decodeURIHelper(getUrlParam("referringRepId"));
    const autoAddSkuId = decodeURIHelper(getUrlParam("skuId"));
    const upsellPage = sessionStorage.getItem("upsell_page");
    const upsellLocation = sessionStorage.getItem("upsell_location");
    const [showUpsellPage, setShowUpsellPage] = useState(false);
    const onFailureOfPageDataFetch = (response) => {
        console.error("Contentful: Error in onFailureOfPageDataFetch - Response:", response);
        datadogRum.addError(response);
    };
    const onSuccessOfPageDataFetch = (response) => {
        const {
            fields: { defaultPage },
        } = response || { fields: {} };
        const query = getCookie("query");
        path !== "siteConfiguration" && regionConflictPopup && checkIfUserIsNotFromSameCountry();
        defaultPage ? (window.location.href = `${renderBaseName(locale)}/product/fitness_programs/${defaultPage}${query ? query : ""}`) : null;
    };
    useEffect(() => {
        const activationCode = getUrlParam("activationCode");
        const emailToken = getUrlParam("token");
        const url = `${MYX_RESET_PASSWORD_PATH}?activationCode=${activationCode}&token=${emailToken}`;
        activationCode && emailToken && redirectWithParam(props.history, url.substring(1));
    }, [props.history]);
    const checkIfUserIsNotFromSameCountry = () => {
        const siteLocale = process.env.BROWSER ? localStorage.getItem("i18nextLng") : locale;
        if (siteLocale && countryCodeForDomain) {
            const sitecountry = siteLocale.split("_")[1];
            const domain = countryCodeForDomain.find((domain) => domain.code === sitecountry.toLowerCase());
            if (user_country && domain?.notAllowedFrom?.includes(user_country)) {
                const localeCode = defaultLanguages[user_country || getCookie("bb_akmes-geoloc-country")];
                const popUpContent = regionConflictPopup?.find((content) => content.fields.id === localeCode);
                const content = popUpContent && <FieldRenderer contents={popUpContent} />;
                if (content) {
                    const data = createDigitalData({ dd: digitalData, path: window.location.pathname + REGION_CONFLICT_POPUP });
                    data && logDigitalData(data);
                    PopupEmitter.emit(TRIGGER_POPUP, {
                        backdropClassName: "content-managed-popup-backdrop",
                        customizedContent: (
                            <div>
                                <style>{basePageCSS}</style>
                                {content}
                            </div>
                        ),
                        header: false,
                        showPopup: true,
                        modalSize: "md",
                        modalClass: "conflict-modal",
                    });
                }
            }
        }
    };
    useEffect(() => {
        setOffersPageId(getOffersPageId());
        const flowPath = path.indexOf("/activatebike/") !== -1 || path.indexOf("/confirmation/") !== -1 ? path.split("?")[0].substring(1) : path.split("/")[1];
        path = path === "/" ? "siteConfiguration" : flowPath;
        let field = getUrlParam("field") || "contentId";
        let value = getUrlParam("value") || contentIdValue;
        let payload = {};
        if (path === "activatebike/") {
            path = path.slice(0, -1);
            payload = { contentTypeId: "default-page", field: "pageUrl", value: path, csr: true };
        } else if (path.indexOf("activatebike") !== -1) {
            payload = { contentTypeId: "default-page", field: "pageUrl", value: path, csr: true };
        } else if (path === "offers") {
            field = "productId";
            value = getOffersPageId() || "default";
            field === "productId" && value === "" && (value = "default");
            payload = { contentTypeId: path, field: field, value: value, csr: true };
        } else if (AuthorizedPaths.indexOf(path) !== -1) {
            payload = { contentTypeId: "default-page", field: "pageUrl", value: path, csr: true };
        } else {
            path = "siteConfiguration";
            payload = { contentTypeId: path, field: "contentId", value: contentIdValue };
            const query = window.location.search;
            query && setCookie("query", query, null);
        }

        if (path !== pageId) {
            dispatch({
                type: pageRouterActions.GET_PAGE_DATA,
                payload,
                callbacks: { success: onSuccessOfPageDataFetch, failure: onFailureOfPageDataFetch },
            });
        }
        if (!sessionStorage.getItem("bypassCaptcha")) {
            checkForCaptchaByPass();
        }
    }, [path]);

    useEffect(() => {
        const currentPath = currentLocation.pathname;
        // Checks if analytics was triggered on page load (used during page change on SPA)
        window.digitalData && !window.analyticsInitialized ? _logPageDigitalData(currentPath) : (window.analyticsInitialized = false);
        if (upsellPage && upsellPage.startsWith("/") && !showUpsellPage) {
            if (
                (currentPath === `/${CREATE_ACCOUNT_PATH}` && upsellLocation === createAccount) ||
                (currentPath === `/${CHECKOUT_PATH}` && upsellLocation === checkout)
            ) {
                setShowUpsellPage(true);
                const url = `${INTERSTITIAL_PAGE}${upsellPage}`;
                redirectWithParam(props.history, url);
            }
        }
    }, [path, digitalData]);
    const _logPageDigitalData = (fullPath) => {
        const isInterstitial = interstitialPage && fullPath.indexOf(INTERSTITIAL_PAGE) !== -1;
        if (window.digitalData.isCheckout && !isInterstitial) {
            delete window.digitalData.isCheckout;
        }
        const relPath = fullPath.split("?")[0];
        const path = relPath.startsWith("/") ? relPath.substring(1) : relPath;
        const page = path.split("/");
        const pageCode = page.join(":");
        let urlPageCode = `landing:bod:web:${pageCode}`;
        if (page.includes("survey")) {
            const id = getUrlParam("sId");
            const code = getUrlParam("productCode");
            const pageNo = getUrlParam("page") || "intro";
            urlPageCode = `landing:bod:web:survey:${id}:${code}:${pageNo}`;
        }
        if (userDetail && userDetail.email && userDetail.isNewUser && MYX_CREATE_ACCOUNT_PATH.includes(path)) {
            urlPageCode += ":password";
        }
        const data = createDigitalData({ dd: window.digitalData, path: urlPageCode });
        if (userDetail && userDetail.guid) data["bod_guid"] = userDetail.guid;
        if (isInterstitial) data.offer_description = interstitialPage?.fields.title;
        window.digitalData = data;
        console.log("DigitalData Logging : Page Event : ", data);
        logDigitalData(data);
    };

    useEffect(() => {
        if (refRepId) {
            sessionStorage.setItem("refRepId", refRepId);
        }
    }, [refRepId]);

    useEffect(() => {
        autoAddSkuId && sessionStorage.setItem("skuId", autoAddSkuId);
    }, [autoAddSkuId]);

    useEffect(() => {
        if (order?.id && !window.digitalData?.basket_id) {
            window.digitalData = { ...window.digitalData, basket_id: order.id };
        }
    }, [order]);

    return (
        <div>
            <style>{basePageCSS}</style>
            {pageTemplateName && page ? <PageTemplateRenderer basePageCSS={basePageCSS} page={page} pageTemplateName={pageTemplateName} {...props} /> : null}
            <SessionTimeOutModal {...props} />
        </div>
    );
};
BDDBasePage.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    pageTemplateName: PropTypes.string,
    props: PropTypes.object,
};

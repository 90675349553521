import React from "react";
import { CTAButton } from "../../common";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
export function SessionTimeOut({ closeModalPopUp }) {
    const { t } = useTranslation();
    return (
        <section className="session-expired-message">
            <p>{t("your-session-has-been-expired")}</p>
            <CTAButton buttonName="OK" onButtonClick={() => closeModalPopUp()} />
        </section>
    );
}
SessionTimeOut.propTypes = {
    closeModalPopUp: PropTypes.func,
    t: PropTypes.func,
};

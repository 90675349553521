import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import App from ".";
import { Loader } from "../common";
import { pageRouterActions } from "../component/page-router/data/page-router-action";
import { CONTENTFUL_CONTENT_ID_BB, CONTENTFUL_CONTENT_ID_BBBIKES, DOMAIN_BBBIKES, DOMAIN_BBBIKES_LOCAL } from "../constants/urls";
import { validateDomain } from "../helper/util";
import configData from "../../tools/config";
import { datadogRum } from "@datadog/browser-rum";

export function AppRouter() {
    window.$contentIdValue = window.$contentIdValue || CONTENTFUL_CONTENT_ID_BB;
    let contentIdValue = window.$contentIdValue;
    const dispatch = useDispatch();
    const [localeConfig, setLocaleConfig] = useState(null);

    const isBBBikes = () => {
        if (window.location.host.indexOf(DOMAIN_BBBIKES) !== -1 || window.location.host.indexOf(DOMAIN_BBBIKES_LOCAL) !== -1) {
            window.$contentIdValue = CONTENTFUL_CONTENT_ID_BBBIKES;
            contentIdValue = window.$contentIdValue;
        }
        console.log("bbbikes:", window.location.host.indexOf(DOMAIN_BBBIKES) !== -1, window.location.host.indexOf(DOMAIN_BBBIKES_LOCAL) !== -1);
    };
    const onSuccessOfPageDataFetch = (response) => {
        const {
            fields: {},
        } = response || { fields: {} };
        setLocaleConfig(true);
    };
    const onFailureOfPageDataFetch = (response) => {
        console.error("Contentful: Error in onFailureOfPageDataFetch - Response:", response);
        datadogRum.addError(response);
    };
    const updateBrowserNavigation = () => {
        sessionStorage.setItem("isBrowserArrow", "true");
    };
    useEffect(() => {
        isBBBikes();
        // Load GTAG
        const {
            GTAG: { INCLUDE_GOOGLE_ANALYTICS_TAG_IN_HOME_PAGE, key, ukKey },
        } = configData || { GTAG: { INCLUDE_GOOGLE_ANALYTICS_TAG_IN_HOME_PAGE: false, key: "", ukKey: "" } };
        const loadGA = INCLUDE_GOOGLE_ANALYTICS_TAG_IN_HOME_PAGE === true;
        const gtagJS = document.createElement("script");
        const gtagScript = document.createElement("script");

        if (loadGA && key && ukKey) {
            const { hostname, pathname } = window.location;
            const hostNameIncludesUK = hostname.includes(".co.uk");
            const currentGTAG = hostNameIncludesUK ? ukKey : key;
            const isIndexPage = pathname === "/";
            const gtagURL = `https://www.googletagmanager.com/gtag/js?id=${currentGTAG}`;

            gtagJS.src = gtagURL;
            gtagJS.async = true;
            gtagScript.text = `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${currentGTAG}');
            `;

            if (isIndexPage) {
                document.body.appendChild(gtagJS);
                document.body.appendChild(gtagScript);
            }
        }

        validateDomain();
        const payload = {
            contentTypeId: "siteConfiguration",
            field: "contentId",
            value: contentIdValue,
            isToGetConfiguration: true,
        };
        dispatch({
            type: pageRouterActions.GET_PAGE_DATA,
            payload,
            callbacks: { success: onSuccessOfPageDataFetch, failure: onFailureOfPageDataFetch },
        });
        window.addEventListener("popstate", updateBrowserNavigation);
        return () => {
            document.body.removeChild(gtagJS);
            document.body.removeChild(gtagScript);
            window.removeEventListener("popstate", updateBrowserNavigation);
        };
    }, []);
    return (
        <Fragment>
            {localeConfig || (!localeConfig && localeConfig !== null) ? (
                <App />
            ) : (
                localeConfig && (
                    <div className="initial-loader">
                        <Loader isToShowLoader={true} />
                    </div>
                )
            )}
        </Fragment>
    );
}

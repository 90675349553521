import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { MyxCoachRefferal } from "./myx-coach-referral";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { useOnClickOutside } from "../../common/util";
import dompurify from "dompurify";
import { logCustomEvents } from "../../../web-analytics";
import * as tealiumTags from "../../../constants/tealium-tags";
import { MyxMembershipAcceptance } from "./";
import { MyxFreeCoachDropdown } from "./myx-free-coach-dropdown";
import useSessionStorageState from "../../../hooks/useSessionStorageState";
import { extractFieldValues } from "../../../helper/util";

export function MyxCoachRefferalSection({
    formProps,
    sectionTitle,
    abondonedUserData,
    coachYesAnswer,
    coachNoAnswer,
    coachYesAnswerText,
    coachNoAnswerText,
    coachSubTitle,
    coachDropdownQuestion,
    checkboxes = [],
    setCoachOptInAnalytics,
    coachSubTitleOneBODi,
    enableOneBodi,
    coachRefferalRef,
    setIsFetchingCoachDetails,
    textContent,
}) {
    const { enableFusion } = useSelector((state) => state.checkout);
    const { onChange, formData } = formProps;
    const coachIdFormData = formData && formData["coachId"];
    const modalRef = useRef();
    const [coachId] = useSessionStorageState("coachId");
    const [showCoachInfo, setShowCoachInfo] = useSessionStorageState("freeCoachSelection", true);
    const [coachAnswer, setCoachAnswer] = useSessionStorageState("coachDDAnswer", "leadwheel");
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [dropdownText, setDropdownText] = useState();
    const sanitizer = dompurify.sanitize;
    const textFieldValues = extractFieldValues(textContent, "textValue");
    const { myx_coach_info_tooltip = "" } = textFieldValues;

    const showCoachInfoModal = () => {
        setShowInfoModal(!showInfoModal);
    };
    useOnClickOutside(modalRef, () => setShowInfoModal(false));
    const validateCoachCheckbox = () => {
        if (!showCoachInfo) {
            logCustomEvents(tealiumTags.myx_yes_coach, tealiumTags.myx_yes_coach);
            onChange("coachId", "");
        }
        setDropdownText(coachYesAnswer);
        setShowCoachInfo(!showCoachInfo);
    };
    const updateCoachOptInAnalytics = (optInVal, labelText, coachOptInId, coachOptInEmail) => {
        const eventData = {
            coach_opt_in: optInVal || "Yes",
            link_label: labelText || coachYesAnswerText,
            coach_id: coachOptInId,
            coach_email: coachOptInEmail,
        };
        setCoachOptInAnalytics(eventData);
    };
    const checkCoachInputType = (id) => {
        const coachInputField = id || "";
        let coachOptInId = "",
            coachOptInEmail = "";
        if (isNaN(coachInputField)) coachOptInEmail = coachInputField;
        else coachOptInId = coachInputField;
        return { coachOptInId: coachOptInId, coachOptInEmail: coachOptInEmail };
    };

    useEffect(() => {
        const payload = { isToValidateCoach: showCoachInfo };
        if (!showCoachInfo) {
            payload["coachId"] = enableFusion ? coachAnswer : "";
            const isCoachOptIn = coachAnswer === "leadwheel" ? "Yes" : "No";
            const selectedDropdownText = coachAnswer === "leadwheel" ? coachYesAnswer : coachNoAnswer;
            updateCoachOptInAnalytics(isCoachOptIn, dropdownText ? dropdownText : selectedDropdownText, "", "");
        } else {
            const coachIdType = checkCoachInputType(coachId);
            updateCoachOptInAnalytics("Yes", coachYesAnswerText, coachIdType.coachOptInId, coachIdType.coachOptInEmail);
        }
        onChange("", "", payload);
    }, [showCoachInfo, coachAnswer, coachId]);

    const coachInfoTooltip = myx_coach_info_tooltip;

    return (
        <section className="coach-section checkout-section">
            <div className="d-flex">
                <h2 className="heading mb-4 mt-4">{sectionTitle}</h2>
                <div className="info-icon">
                    <span className="mb-n1" onClick={showCoachInfoModal}>
                        <i className="fa fa-info-circle ml-2" aria-hidden="true"></i>
                    </span>
                </div>
            </div>
            <div className="input-group d-flex flex-column mx-2">
                <h3 className="heading mb-3">{enableOneBodi ? coachSubTitleOneBODi : coachSubTitle}</h3>
                {showInfoModal && (
                    <div className="coach-info-modal" ref={modalRef}>
                        <div
                            className="coach-info"
                            dangerouslySetInnerHTML={{
                                __html: sanitizer(coachInfoTooltip),
                            }}
                        />
                    </div>
                )}
                <div className="input-group-prepend d-flex flex-column">
                    <Fragment>
                        <div className="input-group-text">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="isToValidateCoach"
                                id="yes-answer"
                                checked={showCoachInfo}
                                onChange={validateCoachCheckbox}
                                aria-label={coachYesAnswerText}
                            />
                            <label className="form-check-label" htmlFor="yes-answer">
                                {coachYesAnswerText}
                            </label>
                        </div>

                        <MyxCoachRefferal
                            {...formProps}
                            abondonedUserData={abondonedUserData}
                            showCoachInfo={showCoachInfo}
                            updateCoachOptInAnalytics={updateCoachOptInAnalytics}
                            checkCoachInputType={checkCoachInputType}
                            coachRefferalRef={coachRefferalRef}
                            setIsFetchingCoachDetails={setIsFetchingCoachDetails}
                            textFieldValues={textFieldValues}
                        />
                    </Fragment>
                    <Fragment>
                        <div className="input-group-text">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="isToValidateCoach"
                                id="no-answer"
                                checked={!showCoachInfo}
                                onChange={validateCoachCheckbox}
                                aria-label={coachNoAnswerText}
                            />
                            <label className="form-check-label" htmlFor="no-answer">
                                {coachNoAnswerText}
                            </label>
                        </div>
                        {enableFusion && !showCoachInfo && (
                            <MyxFreeCoachDropdown
                                {...formProps}
                                coachDropdownQuestion={coachDropdownQuestion}
                                coachNoAnswer={coachNoAnswer}
                                coachYesAnswer={coachYesAnswer}
                                showCoachInfo={showCoachInfo}
                                setCoachAnswer={setCoachAnswer}
                                coachAnswer={coachAnswer}
                                setDropdownText={setDropdownText}
                            />
                        )}
                    </Fragment>
                </div>
            </div>
            <div className="coach-message">
                {enableFusion ? (
                    (coachIdFormData || showCoachInfo) && (
                        <MyxMembershipAcceptance
                            {...formProps}
                            isCoachConsent={true}
                            showCoachInfo={showCoachInfo}
                            checkboxes={checkboxes.filter((checkbox) => checkbox.coachConsent)}
                        />
                    )
                ) : (
                    <MyxMembershipAcceptance
                        {...formProps}
                        isCoachConsent={true}
                        showCoachInfo={showCoachInfo}
                        checkboxes={checkboxes.filter((checkbox) => checkbox.coachConsent)}
                    />
                )}
            </div>
        </section>
    );
}
MyxCoachRefferalSection.propTypes = {
    formProps: PropTypes.object,
    sectionTitle: PropTypes.string,
    abondonedUserData: PropTypes.object,
    checkboxes: PropTypes.array,
    coachDropdownQuestion: PropTypes.string,
    coachYesAnswer: PropTypes.string,
    coachNoAnswer: PropTypes.string,
    coachYesAnswerText: PropTypes.string,
    coachNoAnswerText: PropTypes.string,
    coachSubTitle: PropTypes.string,
    setCoachOptInAnalytics: PropTypes.func,
    coachSubTitleOneBODi: PropTypes.string,
    enableOneBodi: PropTypes.bool,
    setIsFetchingCoachDetails: PropTypes.func,
    coachRefferalRef: PropTypes.shape({
        current: PropTypes.any,
    }),
    textContent: PropTypes.array,
};

/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { OptimizelyFeature } from "@optimizely/react-sdk";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Loader } from "../../common";
import {
    CHECKOUT_PATH,
    COACH_NO_UPSELL_CONFIRMATION,
    COACH_UPSELL_CONFIRMATION,
    CONFIRMATION_PASSWORD_PATH,
    CONFIRMATION_PATH,
    CREATE_ACCOUNT_PATH,
    ODP_FLOW,
    OFFERS_PATH,
} from "../../constants/urls";
import { checkoutActions } from "./data/checkout-page-action";
import { offersActions } from "../offers-page/data/offers-page-action";
import { pageRouterActions } from "../../component/page-router/data/page-router-action";
import { MembershipAcceptance, Multipay, OrderSummary, SecurityContent, ShippingAddress } from "./components";
import { ExpressCheckout, CheckoutFormErrors, CheckoutForm, ZIPDetails } from "../../model";
import { validateForm, validateField } from "../common/validator";
import "./checkout-page-content.scss";
import {
    CaptchaEmitter,
    getCookie,
    intializeGoogleCaptchaV3,
    setCookie,
    clearCookie,
    TRIGGER_CAPTCHA,
    checkFlowType,
    comparePageOrderInFunnel,
    isGBLocale,
    getPayPalClientId,
} from "../../helper/util";
import { CoachRefferal } from "./components/coach-referral";
import { CheckoutAction } from "./checkout-action-panel";
import { redirectWithParam } from "../common/util";
import { put } from "redux-saga/effects";
import { PopupEmitter, TRIGGER_POPUP } from "../../common/popup-emitter";
import AccordionRadioButtons from "./accordion-radio-buttons";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../../model/error-boundary/errorFallback";
import { logCustomEvents, trackEvent } from "../../web-analytics";
import * as tealiumTags from "../../constants/tealium-tags";
import EmailInput from "./components/email-input";
import { defaultPostalCodeUK } from "./constants";

export const CheckoutPageContent = ({ fields, t, ...props }) => {
    const history = props.history;
    const dispatch = useDispatch();
    const isEmailAtCheckout = comparePageOrderInFunnel(CHECKOUT_PATH, CREATE_ACCOUNT_PATH);
    const { userDetail } = useSelector((state) => state.pageRouter);
    const { email, password, firstName, lastName, isNewUser, isExistingFreeUser, isClubUserCanPurchase } = userDetail || {};
    const payPalClientId = getPayPalClientId();

    if (!fields) {
        fields = props.contents.fields;
    }
    const prepopulateUserData = (order, abondonedUserData) => {
        let userData = { email, password, firstName, lastName };
        userData = order && order.addressDetails ? { ...userData, ...order.addressDetails } : userData;
        userData = abondonedUserData && abondonedUserData.profile ? { ...userData, ...abondonedUserData.profile } : userData;
        return userData;
    };
    const {
        securityContent,
        userAcceptanceCheckboxLabel,
        creditCardLabelDisclaimer,
        creditCardPriceDisclaimer,
        freeTrailProductDisclaimer,
        showCoachQuestion,
        textContent,
    } = fields;
    const isCheckoutLandscape = useSelector((state) => state.pageRouter.isCheckoutLandscape);
    const { order, sessionId, isLoading, userSelectedProductId, abondonedUserData } = useSelector((state) => state.offers);
    const assignedCoachType = order?.profile?.assignedCoachType;
    const isAssignedCorporateCoach = assignedCoachType && assignedCoachType === "CORPORATE";
    const isPCUser = userDetail?.customerType?.split(",").includes("PC");
    const {
        zipCodeDetails,
        cyberSource,
        loadPage,
        threeDSAuthentication,
        threeDSecureFlow,
        enableFusion,
        cybsDeviceDataCollection,
        stepUpResponse,
        isCVViFrame,
    } = useSelector((state) => state.checkout);
    const { accessToken, deviceDataCollectionUrl } = cybsDeviceDataCollection || {};
    const { formErrors, paypalEnabled } = useSelector((state) => state.checkout);
    const [expressCheckout, setExpressCheckout] = useState(new ExpressCheckout({ ...prepopulateUserData(order, abondonedUserData) }));
    const expressCheckoutRef = useRef(expressCheckout);
    const coachRefferalRef = useRef(null);
    const [checkoutFormErrors, setCheckoutFormErrors] = useState(new CheckoutFormErrors());
    const [doFieldValidation, triggerFieldValidators] = useState(false);
    const [btnDisable, setBtnDisable] = useState(false);
    const [isZipCodeFailed, setZipCodeFailed] = useState(false);
    const [isZipCodeLookUpFailed, setZipCodeLookUpFailed] = useState(false);
    const [zipCodeLookUpError, setZipCodeLookupError] = useState();
    const [isFormValidated, setIsFormValidated] = useState(false);
    const [apiFormErrors, setAPIFormErrors] = useState(true);
    const [APIFormErrMsgs, setAPIFormErrMsgs] = useState();
    const [submitForm, setSubmitForm] = useState(null);
    const [enrollAuth, doEnrollAuth] = useState(false);
    const [is3DSFailed, setThreeDSError] = useState();
    const [isPaypal, setIsPaypal] = useState(false);
    const [isCybersource, setIsCybersource] = useState(true);
    const [paymentComplete, setPaymentComplete] = useState(false);
    const securityContentModule = <SecurityContent fields={securityContent.fields} />;
    const [payPalLoaderSpinner, setPayPalLoaderSpinner] = useState(false);
    const [isEmailAbleToPurchase, setIsEmailAbleToPurchase] = useState(isEmailAtCheckout ? false : true);
    const [isFetchingCoachDetails, setIsFetchingCoachDetails] = useState(false);
    const [isFetchingEmailDetails, setIsFetchingEmailDetails] = useState(false);
    const paypalFeatureFlagEnabled = paypalEnabled === "true" || paypalEnabled === true;
    const { featureFlagContent } = useSelector((state) => state.pageRouter);
    const { checkoutTwoColumnsLayout: checkoutTwoColumnsLayoutFeatureFlag }: { checkoutTwoColumnsLayout: boolean } = featureFlagContent && featureFlagContent;
    const refRepId = sessionStorage.getItem("refRepId");
    const autoAddSkuId = sessionStorage.getItem("skuId");
    const logTealiumTag = (name: string, message: string) => {
        logCustomEvents(name, name, message);
    };
    const coachReferralCriteria = (isNewUser || refRepId || (enableFusion && !isPCUser && isAssignedCorporateCoach)) && showCoachQuestion;
    const placeOrderClickedRef = useRef(false);

    const setCheckoutData = (newData) => {
        expressCheckoutRef.current = newData;
        setExpressCheckout(newData);
    };

    const updateDigitalData = (value, productId, skuID) => {
        const prevData = sessionStorage.getItem("digitalData") && JSON.parse(sessionStorage.getItem("digitalData"));
        if (prevData) {
            Object.keys(value).forEach(function (val) {
                prevData[val] = value[val];
            });
            sessionStorage.setItem("digitalData", JSON.stringify(prevData));
            trackEvent(prevData, true, productId, skuID);
        }
    };

    const onAddSubSkuToCartSuccess = (order) => {
        const { catalogRefId, commerceItems, productId, subSkuIds } = order?.commerceItems[0] || {};
        if (commerceItems && subSkuIds) {
            const containsHTML = (str) => /<[a-z][\s\S]*>/i.test(str);
            const sku_name = commerceItems?.map((item) => {
                if (containsHTML(item?.cartTitle)) return item?.cartTitle?.replace(/(<([^>]+)>)/gi, "");
                else return item?.cartTitle?.trim();
            });
            updateDigitalData({ product_child_name: [sku_name ? sku_name.join() : ""], product_child_sku: [subSkuIds] }, productId, catalogRefId);
        }
    };

    const onAddSubSkuToCartFailure = () => {
        //TODO: Determine Success Action
    };

    const payPalLoader = (bool) => {
        setPayPalLoaderSpinner(bool);
    };

    const subSkuCheckboxes =
        order && order.pageComponent && Array.isArray(order.pageComponent.checkboxes)
            ? order.pageComponent.checkboxes.filter((checkbox) => checkbox.checkServiceURL)
            : [];
    const checkboxes =
        order && order.pageComponent && Array.isArray(order.pageComponent.checkboxes)
            ? order.pageComponent.checkboxes.filter((checkbox) => !checkbox.checkServiceURL)
            : [];

    const onSubSkuChange = (name, value) => {
        setCheckoutData({ ...expressCheckout, [name]: value });
        const checkbox = subSkuCheckboxes.find((subSku) => subSku.id === name);
        const params = checkbox.hidden.reduce((param, val) => {
            param[val.name] = val.value;
            return param;
        }, {});
        const payload = {
            isToUpdateCart: !value,
            params,
        };
        dispatch({
            type: offersActions.ADD_SUBSKU_TO_CART,
            payload,
            callbacks: { success: onAddSubSkuToCartSuccess, failure: onAddSubSkuToCartFailure },
        });
    };

    const onSuccessOfPlaceOrder = (response) => {
        const {
            order: {
                profile: { assignedCoachType, guid },
            },
            passwordToken,
            hasWarnings,
            warningMessages,
        } = response || { order: { profile: {} } };
        let errorCode;
        if (hasWarnings && warningMessages) {
            errorCode = warningMessages.map((item) => item.errorCode).find((result) => result !== "ADD_ENTITLEMENT_FAILED");
        }
        setBtnDisable(false);
        if (window.digitalData) {
            window.digitalData.order_payment_method = isPaypal ? tealiumTags.paypal : tealiumTags.credit_card;
        }
        !userDetail?.guid &&
            dispatch({
                type: pageRouterActions.SET_USER_DETAILS,
                payload: { ...userDetail, guid },
            });
        const confirmationPageId = !errorCode
            ? assignedCoachType === "CORPORATE"
                ? "COACH_UPSELL_CONFIRMATION"
                : localStorage.getItem("productCode")
            : errorCode;
        sessionStorage.setItem("confirmationPageId", confirmationPageId);

        const getUrl = () => {
            if (isEmailAtCheckout && isNewUser) {
                return `${CONFIRMATION_PASSWORD_PATH}?token=${passwordToken}`;
            }
            if (enableFusion) {
                return assignedCoachType === "CORPORATE" && !errorCode ? COACH_UPSELL_CONFIRMATION : COACH_NO_UPSELL_CONFIRMATION;
            } else return CONFIRMATION_PATH;
        };
        const url = getUrl();
        window.scroll(0, 0);
        dispatch({ type: offersActions.SET_OFFERS_DETAILS, payload: { sessionId: null } });
        redirectWithParam(history, url, true, false, true);
    };

    const onFailureOfPlaceOrder = () => {
        setBtnDisable(false);
    };
    const updateZipCodeStatus = (STATUS) => {
        setBtnDisable(false);
        if (STATUS === "FAILED") {
            setZipCodeFailed(true);
        } else if (STATUS === "LOOKUP_FAILED") {
            setZipCodeLookUpFailed(true);
        } else if (STATUS === "SUCCESS") {
            setZipCodeFailed(false);
            setZipCodeLookUpFailed(false);
        }
    };
    const reDoZipCodeAPICallBack = () => {
        const { shippingPostalCode } = expressCheckout || {};
        const shippable = cartItem && cartItem.isShippable;
        shippable &&
            dispatch({
                type: checkoutActions.GET_ZIPCODE_DETAILS,
                payload: shippingPostalCode,
                zipCodeName: "shippingPostalCode",
                isToCalculateTax: shippable,
            });
    };

    useEffect(() => {
        if (zipCodeLookUpError === false) {
            onPlaceOrder();
        } else if (zipCodeLookUpError) {
            setBtnDisable(false);
        }
    }, [zipCodeLookUpError]);

    const reDoZipCodeAPICall = () => {
        setBtnDisable(true);
        const { billingPostalCode } = expressCheckout || {};
        const shippable = cartItem && cartItem.isShippable;
        billingPostalCode &&
            dispatch({
                type: checkoutActions.GET_ZIPCODE_DETAILS,
                payload: billingPostalCode,
                zipCodeName: "billingPostalCode",
                isToCalculateTax: !shippable,
                callbacks: { success: reDoZipCodeAPICallBack, failure: reDoZipCodeAPICallBack },
            });
    };
    const verifyCoachIdField = () => {
        if (isFetchingCoachDetails) {
            if (coachRefferalRef?.current) {
                coachRefferalRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
                placeOrderClickedRef.current = false;
                return false;
            }
        }
        return true;
    };
    const onPlaceOrder = () => {
        sessionStorage.removeItem("captchaError");
        function placeOrder() {
            setBtnDisable(true);
            setZipCodeLookupError(null);
            const bypassCaptcha = sessionStorage.getItem("bypassCaptcha");
            const gCaptcha = getCookie("g-captcha-data");
            if ((bypassCaptcha === "true" || gCaptcha) && !apiFormErrors) {
                const bypassCaptchaToken = bypassCaptcha === "true" ? { bypassCaptcha: true } : {};
                if (isPaypal) {
                    setIsCybersource(false);
                } else {
                    setIsCybersource(true);
                }
                doExpressCheckout(bypassCaptchaToken);
            } else {
                intializeGoogleCaptchaV3();
            }
        }
        placeOrderClickedRef.current = true;
        if (isPaypal) {
            setIsCybersource(false);
            placeOrder();
        } else {
            setSubmitForm(null);
            if (!isZipCodeLookUpFailed && !validateFormErrors() && !updateZipCodeErrors() && isEmailAbleToPurchase && verifyCoachIdField()) {
                placeOrder();
            } else {
                if (isFetchingEmailDetails) setBtnDisable(true);
                else setBtnDisable(false);
                triggerFieldValidators(true);
            }
            if (isZipCodeLookUpFailed) {
                reDoZipCodeAPICall();
            }
        }

        if (paymentComplete) {
            setCheckoutData({ ...expressCheckout, ccCVV: "", ccNumber: "", ccExpDate: "", ccMonth: "", ccYear: "", ccType: "" });
        }
    };
    const validate3DSCheckout = () => {
        const { id } = order || {};
        put({ type: checkoutActions.UPDATE_ENROLL_AUTH_3DS_RESULT, payload: null });
        dispatch({ type: checkoutActions.CYBERSOURCE_3DS_SETUP_AUTH, payload: { ...expressCheckout, id } });
    };
    const doExpressCheckout = (captcha) => {
        if (!isPaypal && isCybersource && threeDSecureFlow === "true" && (!threeDSAuthentication || is3DSFailed)) {
            const isError = (threeDSAuthentication && threeDSAuthentication.formError === "true") || is3DSFailed;
            isError &&
                dispatch({
                    type: offersActions.GET_CART_DETAILS,
                    payload: { noLoader: true },
                    callbacks: { success: validate3DSCheckout, failure: onFailureOfPlaceOrder },
                });
            setThreeDSError(false);
            !isError && validate3DSCheckout();
        } else {
            completeExpressCheckout(captcha);
        }
    };

    const completeExpressCheckout = (captcha) => {
        const webSourceCode = getCookie("webSourceCode");
        const bypassCaptcha = sessionStorage.getItem("bypassCaptcha");
        const analyticsData = {
            webSourceCode: webSourceCode ? webSourceCode + "*|*" : "",
            ...captcha,
        };

        const expressCheckoutData = isPaypal
            ? { ...expressCheckoutRef.current, bypassCaptcha: bypassCaptcha ? bypassCaptcha : null }
            : { ...expressCheckout, bypassCaptcha: bypassCaptcha ? bypassCaptcha : null };
        if (enableFusion && expressCheckout["coachId"] === "") {
            delete expressCheckoutData["coachConsent"];
        }
        dispatch({
            type: checkoutActions.PLACE_CHECKOUT_ORDER,
            payload: {
                expressCheckout: new CheckoutForm({ ...expressCheckoutData, ...analyticsData, ...stepUpResponse }),
            },
            callbacks: { success: onSuccessOfPlaceOrder, failure: onFailureOfPlaceOrder },
        });
    };
    const cartItem = order && order.commerceItems && order.commerceItems.length && order.commerceItems[0];
    const { isShippable } = cartItem || {};
    const postalCodeKey = isShippable ? "shippingPostalCode" : "billingPostalCode";
    const isFreeTrailProduct = cartItem && cartItem.priceInfo.rawTotalPrice === 0 ? true : false;
    window.digitalData.order = order ? order : {};
    const updateExpressCheckout = (result) => {
        const { isToCalculateTax } = result || {};
        const billing = isToCalculateTax ? !isShippable && isToCalculateTax : true;
        const postalCodeKey = billing ? "billingPostalCode" : "shippingPostalCode";
        Object.keys(result).map((key) => {
            if (result[key]["STATUS"]) {
                updateZipCodeStatus(result[key]["STATUS"]);
                const data = result[key];
                const { STATUS } = data || {};
                const details = { billing: billing, billShipSame: !isShippable, zipcode: expressCheckout[postalCodeKey] };
                if (!billing) {
                    //Override the city and state fields with user enterd values
                    details["city"] = expressCheckout["shippingCity"];
                    details["state"] = expressCheckout["shippingState"];
                }
                STATUS === "SUCCESS" || STATUS === "LOOKUP_FAILED" ? setFormErrors(postalCodeKey, "") : setFormErrors(postalCodeKey, "zip-err-msg");
                if (STATUS === "SUCCESS") {
                    const zipDetails = new ZIPDetails({ ...result[postalCodeKey], ...details });
                    setCheckoutData({ ...expressCheckout, ...zipDetails });
                }
            }
        });
    };
    useEffect(() => {
        if (zipCodeDetails) {
            const { billingPostalCode, shippingPostalCode } = zipCodeDetails || {};
            if (
                doFieldValidation &&
                ((billingPostalCode && billingPostalCode["STATUS"] === "LOOKUP_FAILED") ||
                    (shippingPostalCode && shippingPostalCode["STATUS"] === "LOOKUP_FAILED"))
            ) {
                setZipCodeLookupError(true);
            } else {
                doFieldValidation && setZipCodeLookupError(false);
            }
            updateExpressCheckout(zipCodeDetails);
            updateZipCodeErrors();
        }
    }, [zipCodeDetails]);
    useEffect(() => {
        setAPIFormErrors(false);
        if (formErrors) {
            const { formExceptions } = formErrors || {};
            const threeDSerror =
                formExceptions &&
                formExceptions.some(
                    (error) => error && (error.errorCode === "threeDSValidationFailed" || error.errorCode.indexOf("FailedCreditCardAuth") !== -1),
                );
            setThreeDSError(threeDSerror || (threeDSAuthentication && threeDSAuthentication.formError === "true"));
            setAPIFormErrMsgs(formExceptions);
            setAPIFormErrors(true);

            const captchaError = formExceptions && formExceptions.some((error) => error && error.errorCode === "invalidCaptchaV3");
            if (captchaError) {
                sessionStorage.setItem("captchaError", captchaError);
            }
        }
    }, [formErrors]);

    const calculateTax = ({ name, value }) => {
        try {
            const error = validateField({ name, value });
            const { shippingPostalCode, billingPostalCode } = expressCheckout || {};
            const shippable = cartItem && cartItem.isShippable;
            const postalCode = shippable ? shippingPostalCode : billingPostalCode;
            if (error) {
                setFormErrors(name, error);
            } else if (postalCode && postalCodeKey === name) {
                setFormErrors(name, "");
                dispatch({
                    type: checkoutActions.GET_ZIPCODE_DETAILS,
                    payload: postalCode,
                    zipCodeName: name,
                    isToCalculateTax: true,
                });
            } else if (billingPostalCode) {
                dispatch({
                    type: checkoutActions.GET_ZIPCODE_DETAILS,
                    payload: billingPostalCode,
                    zipCodeName: name,
                    isToCalculateTax: false,
                });
            }
        } catch (error) {
            console.error("Error at calculateTax method", error);
        }
    };

    const onChange = (name, value, fieldValues) => {
        if (name === "ccNumber" || (fieldValues && fieldValues.ccNumber)) {
            const errors =
                APIFormErrMsgs &&
                APIFormErrMsgs.filter((error) => {
                    const { errorCode } = error || {};
                    return !(errorCode && errorCode.startsWith("FailedCreditCardAuth")) || !(errorCode && errorCode.startsWith("threeDSValidationFailed"));
                });
            APIFormErrMsgs && setAPIFormErrMsgs(errors);
            checkoutFormErrors && checkoutFormErrors[name] && setFormErrors(name, "");
        }
        if (name === "ccCVV" && isCVViFrame) {
            const { empty, valid, couldBeValid } = value || {};
            if (!couldBeValid && !valid) {
                setFormErrors("ccCVV", t("cvv-err-msg"));
            } else if (empty) {
                setFormErrors("ccCVV", t("cvv-err-msg-req"));
            } else if (valid && couldBeValid) {
                setFormErrors("ccCVV", "");
            }
            setCheckoutData({ ...expressCheckout, isCVVValid: !empty ? couldBeValid && valid : null });
            return;
        }
        if (name === "shippingPostalCode" || name === "billingPostalCode") {
            value = value && value.toUpperCase();
        }
        if (fieldValues && typeof fieldValues === "object") {
            const expressData = { ...expressCheckout, ...fieldValues };
            setCheckoutData(expressData);
        } else {
            setCheckoutData({ ...expressCheckout, [name]: value });
        }
        if (name === "email" && isEmailAtCheckout) {
            if (!value.length) setFormErrors("email", "");
            if (value) setIsFetchingEmailDetails(true);
            else setIsFetchingEmailDetails(false);
            placeOrderClickedRef.current = false;
        }
    };
    const setFormErrors = (name, value, multiple?, translationsArgs?) => {
        if (multiple && typeof multiple === "object") {
            //Please pass the translated error message
            setCheckoutFormErrors({ ...checkoutFormErrors, ...multiple });
        } else {
            setCheckoutFormErrors({ ...checkoutFormErrors, [name]: value ? t(value, translationsArgs) : "" });
        }
    };
    const validateFormErrors = () => {
        const skipPassword = isExistingFreeUser || isClubUserCanPurchase || isEmailAtCheckout;
        const errors = validateForm(expressCheckout, t, skipPassword, order && order.validateUserConfirmations, false, isCVViFrame);
        const { shippingPostalCode, billingPostalCode } = zipCodeDetails || {};
        const shippable = cartItem && cartItem.isShippable;
        const shippingCode = shippingPostalCode && shippingPostalCode["STATUS"] === "FAILED";
        const billingCode = billingPostalCode && billingPostalCode["STATUS"] === "FAILED";
        const zipCodeErrors = {};
        const existingUserError = checkoutFormErrors["email"];
        if (shippable && shippingCode) {
            zipCodeErrors["shippingPostalCode"] = t("zip-err-msg");
        }
        if (billingCode) {
            zipCodeErrors["billingPostalCode"] = t("zip-err-msg");
        }
        setIsFormValidated(true);
        if (errors || existingUserError) {
            existingUserError
                ? setCheckoutFormErrors({ ...checkoutFormErrors, ...errors, ...zipCodeErrors, email: existingUserError })
                : setCheckoutFormErrors({ ...checkoutFormErrors, ...errors, ...zipCodeErrors });
            if (errors) {
                const errorArray = Object.values(errors);
                errorArray.forEach((error: string) => {
                    if (error) {
                        logTealiumTag(tealiumTags.digital_checkout_error, error);
                    }
                });
            }

            return true;
        } else if (Object.keys(zipCodeErrors).length) {
            setCheckoutFormErrors({ ...checkoutFormErrors, ...zipCodeErrors });
            return true;
        } else {
            setCheckoutFormErrors(new CheckoutFormErrors());
            return false;
        }
    };

    function validatePaypalErrors(displayErrors = true) {
        const skipPassword = isExistingFreeUser || isClubUserCanPurchase || isEmailAtCheckout;
        const errors = validateForm(expressCheckoutRef.current, t, skipPassword, order && order.validateUserConfirmations, false, isCVViFrame);
        const { continuity, legalTermsCondition } = errors || {};
        const hasErrors = continuity || legalTermsCondition;

        if (hasErrors) {
            if (displayErrors) {
                if (continuity) {
                    logTealiumTag(tealiumTags.digital_checkout_error, continuity);
                }
                if (legalTermsCondition) {
                    logTealiumTag(tealiumTags.digital_checkout_error, legalTermsCondition);
                }
                setCheckoutFormErrors({ continuity, legalTermsCondition });
                triggerFieldValidators(true);
            }
            return true;
        }

        return false;
    }

    const onCartDetailsFetchSuccess = (order) => {
        const { commerceItems } = order || {};
        const hasPreviousCaptchaError = sessionStorage.getItem("captchaError");
        if (commerceItems instanceof Array && commerceItems.length === 0 && !hasPreviousCaptchaError) {
            redirectWithParam(history, OFFERS_PATH);
        }
    };
    const onCartDetailFetchFailure = () => {
        redirectWithParam(history, OFFERS_PATH);
    };
    const updateZipCodeErrors = () => {
        const { shippingPostalCode, billingPostalCode } = zipCodeDetails || {};
        const shippable = cartItem && cartItem.isShippable;
        const shippingCode = shippingPostalCode && shippingPostalCode["STATUS"] === "FAILED";
        const billingCode = billingPostalCode && billingPostalCode["STATUS"] === "FAILED";
        shippable && shippingCode && !checkoutFormErrors["shippingPostalCode"] && setFormErrors("shippingPostalCode", "zip-err-msg");
        billingCode && !checkoutFormErrors["billingPostalCode"] && setFormErrors("billingPostalCode", "zip-err-msg");
        const isError = shippable ? shippingCode || billingCode : billingCode;
        if (isError) {
            setZipCodeFailed(true);
        } else {
            setZipCodeFailed(false);
        }
        return isError;
    };
    useEffect(() => {
        if (isEmailAtCheckout && isEmailAbleToPurchase && userDetail) {
            if (placeOrderClickedRef.current) onPlaceOrder();
        }
    }, [userDetail]);
    useEffect(() => {
        if (isZipCodeFailed) {
            updateZipCodeErrors();
        }
        if (isFormValidated || doFieldValidation) {
            if (placeOrderClickedRef.current) {
                const element = document.querySelector(".error-msg");
                if (element && !element.classList.contains("optional-field")) {
                    element.scrollIntoView({ behavior: "smooth", block: "center" });
                    placeOrderClickedRef.current = false;
                    setBtnDisable(false);
                }
            }
            setIsFormValidated(false);
        }
    }, [checkoutFormErrors]);
    useEffect(() => {
        if (sessionId) {
            const { guid, oid, email, isExistingFreeUser } = userDetail || {};
            if (oid && !order) {
                if (guid && isExistingFreeUser) {
                    dispatch({
                        type: checkoutActions.USER_LOGIN_SYNCUP_ATG,
                        payload: { guid, oid, email, abondonedUser: userDetail },
                        callbacks: { success: onCartDetailsFetchSuccess, failure: onCartDetailFetchFailure },
                    });
                } else {
                    dispatch({
                        type: offersActions.GET_CART_ABANDONED_ORDER_DETAILS,
                        payload: { oid },
                        callbacks: { success: onCartDetailsFetchSuccess, failure: onCartDetailFetchFailure },
                    });
                }
            } else {
                sessionStorage.removeItem("oid");
            }
            !oid &&
                !order &&
                dispatch({
                    type: offersActions.GET_CART_DETAILS,
                    callbacks: { success: onCartDetailsFetchSuccess, failure: onCartDetailFetchFailure },
                });
        }
    }, [sessionId]);
    useEffect(() => {
        cyberSource && sessionId && dispatch({ type: checkoutActions.GET_CYBERSOURCE_KEY });
        cyberSource === false ? dispatch({ type: checkoutActions.LOAD_PAGE, payload: true }) : null;
    }, [cyberSource]);
    useEffect(() => {
        enrollAuth &&
            dispatch({
                type: checkoutActions.CYBERSOURCE_3DS_ENROLL_AUTH,
                payload: { ...expressCheckout, id, cybsDeviceDataCollection },
            }) &&
            doEnrollAuth(false);
    }, [enrollAuth]);

    useEffect(() => {
        window.scroll(0, 0);
        window.digitalData && sessionStorage.setItem("digitalData", JSON.stringify(window.digitalData));
        const hasPreviousCaptchaError = sessionStorage.getItem("captchaError");
        if (!isEmailAtCheckout && (!userDetail || !email || (!password && isNewUser)) && !hasPreviousCaptchaError) {
            redirectWithParam(props.history, CREATE_ACCOUNT_PATH);
        }
        const date = new Date();
        date.setTime(date.getTime() + 115 * 1000);
        setCookie("g-captcha-data", "false", date);
        CaptchaEmitter.addListener(TRIGGER_CAPTCHA, (data) => {
            setSubmitForm(data);
            setAPIFormErrors(false);
        });
        intializeGoogleCaptchaV3();
        window.addEventListener(
            "message",
            (event) => {
                const { origin, data } = event || {};
                if (origin && origin.indexOf("cardinalcommerce.com") !== -1) {
                    const data = JSON.parse(event.data);
                    if (data.MessageType === "profile.completed" && data.Status) {
                        doEnrollAuth(true);
                    }
                } else if (data && data["type"] === "stepUpResponse") {
                    PopupEmitter.emit(TRIGGER_POPUP, { showPopup: false });
                    dispatch({ type: checkoutActions.STEPUP_RESPONSE, payload: data });
                }
            },
            false,
        );
        window.postMessage({}, "*");
        payPalLoader(false);

        return () => {
            sessionStorage.removeItem("digitalData");
        };
    }, []);

    useEffect(() => {
        if (submitForm) {
            const { submitForm: isToPlaceOrder, "g-recaptcha-response": captcha } = submitForm || {};
            onChange("g-recaptcha-response", captcha);
            isToPlaceOrder && doExpressCheckout({ "g-recaptcha-response": captcha });
        }
    }, [submitForm]);
    const formProps = {
        t,
        onChange,
        setFormErrors,
        formErrors: checkoutFormErrors,
        formData: expressCheckout,
        validateField,
        doFieldValidation,
        calculateTax,
        cartItem,
        onCartDetailsFetchSuccess,
        onCartDetailFetchFailure,
        dispatch,
        setIsPaypal,
        isPaypal,
        setPaymentComplete,
        setIsEmailAbleToPurchase,
        setIsFetchingEmailDetails,
        setCheckoutData,
    };
    useEffect(() => {
        abondonedUserData &&
            abondonedUserData.profile &&
            setCheckoutData({
                ...expressCheckout,
                ...abondonedUserData.profile,
            });
    }, [abondonedUserData]);
    useEffect(() => {
        if (order) {
            order.addressDetails &&
                setCheckoutData({
                    ...expressCheckout,
                    ...order.addressDetails,
                });
            // Auto-populate estimated VAT in UK checkout
            const { billingCity } = order?.addressDetails || {};
            if (isGBLocale() && !billingCity) {
                dispatch({
                    type: checkoutActions.GET_ZIPCODE_DETAILS,
                    payload: defaultPostalCodeUK,
                    zipCodeName: "billingPostalCode",
                    isToCalculateTax: true,
                });
            }
        }
    }, [order]);
    const payerAuthTemplate = (stepUpUrl, accessToken) => {
        return (
            <div className="stepUpFormPop">
                <div className="d-flex w-100 justify-content-center">
                    <iframe
                        id="step-up-iframe"
                        name="step-up-iframe"
                        width="350"
                        height="350"
                        style={{ border: "none", position: "absolute", width: "100%", height: "100%" }}
                    ></iframe>
                    <form id="step-up-form" target="step-up-iframe" method="post" action={stepUpUrl}>
                        <input type="hidden" id="step-up-form_JWT" name="JWT" value={accessToken} />
                        <input type="hidden" id="step-up-form_MD" name="MD" value="" />
                    </form>
                </div>
            </div>
        );
    };
    useEffect(() => {
        if (threeDSAuthentication && threeDSecureFlow === "true") {
            const { status, formError, paymentError, formExceptions, stepUpUrl, accessToken } = threeDSAuthentication || {};
            if (status === "AUTHORIZED") {
                onPlaceOrder();
            } else if ((formError === "true" || paymentError) && formExceptions) {
                dispatch({ type: checkoutActions.CHECKOUT_FORM_ERROR, payload: threeDSAuthentication });
                onFailureOfPlaceOrder();
            } else if (status === "PENDING_AUTHENTICATION" && stepUpUrl) {
                PopupEmitter.emit(TRIGGER_POPUP, {
                    closeButton: false,
                    showPopup: true,
                    modalId: "three-d-s-popup",
                    modalHeader: t("verify-cc-information"),
                    customizedContent: <div>{payerAuthTemplate(stepUpUrl, accessToken)}</div>,
                });
                setTimeout(() => {
                    const form = document.getElementById("step-up-form");
                    form && form.submit();
                }, 300);
            }
        }
    }, [threeDSAuthentication]);
    useEffect(() => {
        if (stepUpResponse.type) {
            onPlaceOrder();
        }
    }, [stepUpResponse]);
    useEffect(() => {
        if (cybsDeviceDataCollection) {
            const { status, formError } = cybsDeviceDataCollection || {};
            if (status === "COMPLETED") {
                setTimeout(() => {
                    const ddcForm = document.getElementById("ddc-form");
                    ddcForm && ddcForm.submit();
                }, 300);
            } else if (formError === "true") {
                setBtnDisable(false);
            }
        }
    }, [cybsDeviceDataCollection]);

    useEffect(() => {
        const productId = (order && order.commerceItems.length > 0 && order.commerceItems[0].productId) || "";
        const flowType = checkFlowType(productId);
        return () => {
            const id = sessionStorage.getItem("upsell_page");
            if (!id && history.action === "POP" && flowType === ODP_FLOW && !window.location.pathname.includes("checkout")) {
                clearCookie(["userData"]);
                history.go(-2);
                setTimeout(() => {
                    window.history.pushState(null, document.title, null);
                }, 200);
            }
        };
    }, [history, order]);

    useEffect(() => {
        autoAddSkuId && sessionStorage.removeItem("skuId");
    }, [autoAddSkuId]);

    return (
        <section className="checkout-content w-100 d-flex">
            {!isLoading && sessionId && (cyberSource ? loadPage : loadPage) ? (
                order && (
                    <PayPalScriptProvider
                        deferLoading={true}
                        options={{
                            clientId: payPalClientId,
                            vault: true,
                            intent: "tokenize",
                        }}
                    >
                        <OptimizelyFeature feature="bb_com__checkout_two_column_layout">
                            {(featureEnabled: boolean, variables: { two_column_layout: boolean }) => {
                                const twoColumnsLayoutEnabled = checkoutTwoColumnsLayoutFeatureFlag && featureEnabled && variables.two_column_layout;
                                return (
                                    <div className={`checkout-container w-100 ${twoColumnsLayoutEnabled ? "checkout-container--two-columns" : ""}`}>
                                        {isEmailAtCheckout && (
                                            <div className="email-address">
                                                <h2>{t("email-address")}</h2>
                                                <EmailInput {...formProps} />
                                            </div>
                                        )}
                                        <div className={`customer-billing ${btnDisable ? "pe-none" : ""}`}>
                                            <h2>{t("payment-information")}</h2>
                                            <AccordionRadioButtons
                                                t={t}
                                                formProps={formProps}
                                                paypalFeatureFlagEnabled={paypalFeatureFlagEnabled}
                                                twoColumnsLayoutEnabled={twoColumnsLayoutEnabled}
                                            />
                                            {cartItem.isShippable && <ShippingAddress t={t} {...formProps} />}
                                        </div>
                                        <div className="order-summary-content">
                                            <OrderSummary
                                                {...formProps}
                                                checkboxes={subSkuCheckboxes}
                                                onSubSkuChange={onSubSkuChange}
                                                order={order}
                                                productId={userSelectedProductId}
                                                cartTitle={cartItem.cartTitle}
                                                isFreeTrailProduct={isFreeTrailProduct}
                                                {...props}
                                            />
                                            <Multipay
                                                orderTotal={order.priceInfo && order.priceInfo.total}
                                                isFreeTrailProduct={isFreeTrailProduct}
                                                creditCardLabelDisclaimer={creditCardLabelDisclaimer}
                                                creditCardPriceDisclaimer={creditCardPriceDisclaimer}
                                                freeTrailProductDisclaimer={freeTrailProductDisclaimer}
                                            />
                                        </div>
                                        <div className={`customer-billing ${btnDisable ? "pe-none" : ""}`}>
                                            {coachReferralCriteria && (
                                                <CoachRefferal
                                                    isPCUser={isPCUser}
                                                    {...formProps}
                                                    abondonedUserData={abondonedUserData}
                                                    order={order}
                                                    coachRefferalRef={coachRefferalRef}
                                                    setIsFetchingCoachDetails={setIsFetchingCoachDetails}
                                                    textContent={textContent}
                                                />
                                            )}
                                            <MembershipAcceptance {...formProps} checkboxes={checkboxes} content={userAcceptanceCheckboxLabel.content} />
                                            <ErrorBoundary
                                                FallbackComponent={ErrorFallback}
                                                resetKeys={[isPaypal]}
                                                onError={(error) => {
                                                    logTealiumTag(tealiumTags.digital_checkout_error, error.message);
                                                }}
                                            >
                                                <CheckoutAction
                                                    t={t}
                                                    zipCodeLookUpError={zipCodeLookUpError}
                                                    apiFormErrors={apiFormErrors}
                                                    APIFormErrMsgs={APIFormErrMsgs}
                                                    doFieldValidation={doFieldValidation}
                                                    isCheckoutLandscape={isCheckoutLandscape}
                                                    btnDisable={btnDisable}
                                                    onPlaceOrder={onPlaceOrder}
                                                    expressCheckout={expressCheckout}
                                                    securityContentModule={securityContentModule}
                                                    fieldsToValidate={order && order.validateUserConfirmations}
                                                    isPaypal={isPaypal}
                                                    expressCheckoutRef={expressCheckoutRef}
                                                    paypalLoader={payPalLoaderSpinner}
                                                    setPayPalLoader={setPayPalLoaderSpinner}
                                                    paymentComplete={paymentComplete}
                                                    setPaypalLoader={payPalLoader}
                                                    setPaymentComplete={setPaymentComplete}
                                                    setCheckoutData={setCheckoutData}
                                                    paypalFeatureFlagEnabled={paypalFeatureFlagEnabled}
                                                    validateFormErrors={validatePaypalErrors}
                                                    onPaypalButtonClickCb={() => setAPIFormErrors(false)}
                                                />
                                            </ErrorBoundary>
                                            {!isCheckoutLandscape ? securityContentModule : null}
                                            {cybsDeviceDataCollection && deviceDataCollectionUrl && (
                                                <div id="cybs-3ds-ddc">
                                                    <iframe name="ddc-iframe" height="10" width="10" style={{ display: "none" }}></iframe>
                                                    <form id="ddc-form" target="ddc-iframe" method="POST" action={deviceDataCollectionUrl}>
                                                        <input type="hidden" name="JWT" value={accessToken} />
                                                    </form>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                );
                            }}
                        </OptimizelyFeature>
                    </PayPalScriptProvider>
                )
            ) : (
                <Loader className="loader-wrapper" isToShowLoader={true} />
            )}
        </section>
    );
};
CheckoutPageContent.propTypes = {
    fields: PropTypes.object,
    props: PropTypes.object,
};

import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { PopupEmitter, SessionEmitter, TRIGGER_POPUP, TRIGGER_SESSION_MODAL } from "../../common/popup-emitter";
import { pageRouterActions } from "../../component/page-router/data/page-router-action";
import {
    CREATE_ACCOUNT_PATH,
    DEFAULT_OFFER_CODE,
    MYX_ACTIVATE_BIKE_PATH,
    MYX_ACTIVATION_SUCCESS,
    MYX_CHECKOUT_PATH,
    MYX_CREATE_ACCOUNT_PATH,
    MYX_EDIT_EMAIL_PATH,
    MYX_FORGOT_PASSWORD_PATH,
    MYX_LOGIN_PATH,
    MYX_ORDER_CONFIRMATION_PATH,
    MYX_RESET_PASSWORD_PATH,
    MYX_SESSION_EXPIRED_PATH,
    OFFERS_PATH,
    SESSION_TIME_LIMIT,
    DEFAULT_ODP_CODE,
    CONFIRMATIONPATHS,
} from "../../constants/urls";
import { clearCookie, getFunnelPath, checkIfODPFlow } from "../../helper/util";
import { SessionTimeOut } from ".";

export function SessionTimeOutModal(props) {
    const { location } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const [showSessionModal, setShowModal] = useState(false);
    function redirectUser() {
        const funnelPath = getFunnelPath() || [];
        const offerId = localStorage.getItem("offerId") || DEFAULT_OFFER_CODE;
        const isODPFlow = checkIfODPFlow(offerId);
        const isConfirmationPage = CONFIRMATIONPATHS.filter((page) => page.includes(location.pathname.split("/")[1])).length > 0;
        const countryCode = window.location.pathname.split("/")[1];
        const landingPageUrl = sessionStorage.getItem("landingPageUrl") || "/";
        const finalRoute = isODPFlow ? `${CREATE_ACCOUNT_PATH}?offerId=${DEFAULT_ODP_CODE}` : funnelPath[0] || OFFERS_PATH;
        return (window.location = isConfirmationPage ? landingPageUrl : `/${countryCode}/${finalRoute}`);
    }
    const clearStorage = () => {
        clearCookie(["userData"]);
        sessionStorage.removeItem("sessionId");
        localStorage.removeItem("userLastActiveTime");
        localStorage.removeItem("sessionExpired");
        sessionStorage.removeItem("oid");
        redirectUser();
    };
    const logoutSuccess = () => {
        setShowModal(false);
        PopupEmitter.emit(TRIGGER_POPUP, { showPopup: false });
        clearStorage();
    };
    const logoutFailure = () => {
        //TOD
    };
    const closeModalPopUp = () => {
        dispatch({
            type: pageRouterActions.SESSION_LOGOUT,
            payload: {},
            callbacks: { success: logoutSuccess, failure: logoutFailure },
        });
    };
    const isMyxPage = () => {
        let isMyxPage = false;
        const myxPagesArray = [
            MYX_ACTIVATE_BIKE_PATH,
            MYX_ACTIVATION_SUCCESS,
            MYX_CHECKOUT_PATH,
            MYX_CREATE_ACCOUNT_PATH,
            MYX_EDIT_EMAIL_PATH,
            MYX_FORGOT_PASSWORD_PATH,
            MYX_LOGIN_PATH,
            MYX_ORDER_CONFIRMATION_PATH,
            MYX_RESET_PASSWORD_PATH,
        ];
        myxPagesArray.find((myxPage) => {
            if (window.location.href.includes(myxPage)) {
                isMyxPage = true;
            }
            return isMyxPage;
        });
        return isMyxPage;
    };
    const isMyxSessionExpired = () => {
        return window.location.href.includes(MYX_SESSION_EXPIRED_PATH);
    };
    const triggerSessionTimeoutModalOrMyxPage = (shouldShowModal = true) => {
        if (isMyxPage()) {
            history && history.push(MYX_SESSION_EXPIRED_PATH);
        } else if (!isMyxSessionExpired()) {
            setShowModal(shouldShowModal);
        }
    };

    const userSessionIdleTimeoutHandler = () => {
        const currentTime = new Date().getTime();
        const lastActiveTime = localStorage.getItem("userLastActiveTime");
        const activeTimeDifference = currentTime - lastActiveTime;
        const sessionTimeoutThreshold = SESSION_TIME_LIMIT - activeTimeDifference;
        if (activeTimeDifference <= SESSION_TIME_LIMIT) {
            window.sessionTimer = window.setTimeout(() => {
                userSessionIdleTimeoutHandler();
            }, sessionTimeoutThreshold);
        } else {
            localStorage.setItem("sessionExpired", true);
            triggerSessionTimeoutModalOrMyxPage();
        }
    };
    useEffect(() => {
        localStorage.setItem("sessionExpired", false);
        const sessionEmitter = SessionEmitter.addListener(TRIGGER_SESSION_MODAL, (isToShow) => {
            isToShow ? triggerSessionTimeoutModalOrMyxPage(isToShow) : userSessionIdleTimeoutHandler();
        });
        const sessionExpired = localStorage.getItem("sessionExpired");
        if (sessionExpired) {
        }
        window.addEventListener("storage", (event) => {
            if (event.key === "sessionExpired") {
                (event.oldValue && !event.newValue) || (event.newValue && event.newValue === "true") ? triggerSessionTimeoutModalOrMyxPage() : null;
            } else if (event.key === "userLastActiveTime") {
                if (event.oldValue && !event.newValue) {
                    clearStorage();
                    return;
                }
                userSessionIdleTimeoutHandler();
            }
        });
        return () => {
            sessionEmitter.remove();
        };
    }, []);

    return (
        <Fragment>
            {showSessionModal &&
                PopupEmitter.emit(TRIGGER_POPUP, {
                    header: false,
                    modalId: "session-timeout",
                    modalSize: "sm",
                    modalClass: "session-expiry-modal",
                    closeButton: false,
                    showPopup: true,
                    customizedContent: <SessionTimeOut closeModalPopUp={closeModalPopUp} />,
                })}
        </Fragment>
    );
}

SessionTimeOutModal.propTypes = {
    location: PropTypes.object,
};

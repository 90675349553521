import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";
import { OptimizelyProvider, createInstance } from "@optimizely/react-sdk";
import { UserInfo } from "@optimizely/react-sdk/dist/utils";
import PropTypes from "prop-types";
import { BDDBasePage } from "../component/page";
import { pageRouterActions } from "../component/page-router/data/page-router-action";
import { offersActions } from "../module/offers-page/data/offers-page-action";
import { checkoutActions } from "../module/checkout-page/data/checkout-page-action";
import { getSiteLocale, intializeGoogleCaptchaV3, renderBaseName, initializeDataDogRUM, shouldFetchSessionId } from "../helper/util";
import configData from "../../tools/config";

if (!process.env.BROWSER && !global.document) {
    global.document = {}; // Temporarily define window for server-side
}

const optimizelyClient = createInstance({
    sdkKey: configData.OPTIMIZELY.sdkKey,
});

const App = () => {
    const { locale, isDesktopView } = useSelector((state) => state.pageRouter);
    const { merlineConfig, datadogRumEnabled, datadogRumSamplingRate } = useSelector((state) => state.checkout);
    const basename = renderBaseName(locale);
    const dispatch = useDispatch();
    const sessionId = useSelector((state) => state.offers.sessionId);
    const siteKey = configData.CAPTCHA.siteKey;
    const siteLocale = getSiteLocale();
    const envName = window.harmonyEnvironment;

    const optimizelyUser: UserInfo = {
        id: sessionId,
        attributes: {
            mobile: !isDesktopView,
            locale: siteLocale,
            environment: envName,
        },
    };

    const handleResize = () => {
        dispatch({ type: pageRouterActions.HANDLE_WINDOW_RESIZE });
    };

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
        script.addEventListener("load", intializeGoogleCaptchaV3);
        document.body.appendChild(script);
    }, [siteKey]);

    useEffect(() => {
        shouldFetchSessionId(false) && !sessionId && dispatch({ type: offersActions.GET_SESSION_ID });
    }, []);

    useEffect(() => {
        sessionId && !merlineConfig && dispatch({ type: checkoutActions.GET_CONFIGURATIONS });
    }, [sessionId]);

    useEffect(() => {
        if (datadogRumEnabled === "true" || datadogRumEnabled === true) {
            initializeDataDogRUM(datadogRumSamplingRate);
            window.DD_RUM_Config = { samplingRate: datadogRumSamplingRate, isEnabled: true };
        } else {
            window.DD_RUM_Config = { samplingRate: "", isEnabled: false };
        }
    }, [datadogRumEnabled]);

    return (
        <OptimizelyProvider optimizely={optimizelyClient} user={optimizelyUser}>
            {basename && (
                <Router basename={basename}>
                    <Fragment>
                        <Route path="/" exact component={BDDBasePage} />
                        <Route path="/:page" component={BDDBasePage} />
                    </Fragment>
                </Router>
            )}
        </OptimizelyProvider>
    );
};
App.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default App;

import validator from "validator";
import * as regex from "../../../constants/regex";
import { ExpiryDate, validateEligibleCards } from "../../../helper/util";

export const validateForm = (formData, t, skipPassword, validateUserConfirmations, hasDefaultCC = false, isCVViFrame) => {
    let hasError = false;
    let formErrors = {};
    let skipValidation = false;
    for (const property in formData) {
        if (property === "continuity" || property === "legalTermsCondition" || property === "coachConflict")
            skipValidation = !(validateUserConfirmations && validateUserConfirmations.some((field) => property === field));
        const error =
            (property === "password" && skipPassword) || (property === "ccCVV" && hasDefaultCC) || skipValidation
                ? null
                : validateField({ name: property, value: formData[property], formData, hybridValidator: true, isCVViFrame });
        error && (hasError = true);
        if ((property === "ccMonth" || property === "ccYear") && error === "exp-date-required") {
            formErrors = { ...formErrors, ["ccExpiry"]: formErrors["ccExpiry"] ? formErrors["ccExpiry"] : t(error) };
        } else if (property === "ccExpDate") {
            formErrors = { ...formErrors, ["ccExpiry"]: formErrors["ccExpiry"] ? formErrors["ccExpiry"] : t(error) };
        } else {
            if (error && (property === "ccMonth" || property === "ccYear")) {
                formErrors = { ...formErrors, ["ccExpiry"]: "" };
            }
            formErrors = { ...formErrors, [property]: t(error) };
        }

        if (isCVViFrame) {
            if (property === "isCCNumberValid" && formData["isCCNumberValid"]) {
                formErrors = { ...formErrors, ["ccNumber"]: "" };
            }

            if (property === "ccType" && formData["ccType"] && !validateEligibleCards(formData["ccType"])) {
                formErrors = { ...formErrors, ["ccCVV"]: "" };
            }

            if (property === "isCVVValid" && formData["isCVVValid"]) {
                formErrors = { ...formErrors, ["ccCVV"]: "" };
            }

            if (property === "ccNumber" && formData["isMyXFlow"] && error) {
                const newError = validateField({ name: property, value: formData[property], formData, hybridValidator: true, isCVViFrame: false });

                formErrors = { ...formErrors, ["ccNumber"]: newError ? t(newError) : "" };
            }
        }
    }

    hasError = Object.values(formErrors).some((item) => item !== "");
    return hasError ? formErrors : null;
};

export const validateField = ({ name, value, callback = null, formData = null, hybridValidator = false, isCVViFrame }) => {
    let validators = [];
    hybridValidator && getValidationsForBlank()[name] && (validators = [...getValidationsForBlank()[name]]);
    getValidations()[name] && (validators = [...validators, ...getValidations()[name]]);
    if (!validators) {
        return "";
    }

    //Spaces are not allowed in password field, so skipping trim for passward value
    name !== "password" && (value = typeof value === "string" ? value.trim() : value);
    const error =
        validators.length &&
        validators.find((validate) => {
            if (validate.method(value, formData, isCVViFrame)) {
                return validate;
            }
        });
    if (callback && callback instanceof Function) {
        callback(name, error ? error.message : "");
    } else {
        return error ? error.message : "";
    }
};
const validateMonthAndYear = (value, type) => {
    const date = new Date();
    const currentYear = date.getFullYear();
    const YEAR_LIMIT = currentYear + 14;
    const year = `20${value}`;
    if (type === "month") {
        return Number(value) > 12 || Number(value) < 1 ? true : false;
    } else if (type === "year") {
        return !value || Number(year) > YEAR_LIMIT ? true : false;
    }
};
const getZIPCodeValidation = () => {
    const locale = localStorage.getItem("i18nextLng");
    switch (locale) {
        case "en_CA":
        case "fr_CA":
            return /^[a-zA-Z]{1}\d[a-zA-Z]{1}? ?\d[a-zA-Z]{1}\d$/;
        case "fr_FR":
            return /^(?:[0-8]\d|9[0-8])\d{3}$/;
        case "en_GB":
            return /^[a-zA-Z]{1,2}\d[a-zA-Z\d]? ?\d[a-zA-Z]{2}$/;
        case "es_MX":
            return /^\d{5}$/;
        case "en_US":
        case "es_US":
            return /^\d{5}(?:[-\s]\d{4})?$/;
        default:
            return /^[0-9]*$/u;
    }
};
const getValidations = () => {
    return {
        firstName: [
            {
                method: (value, formData) => ((formData && !formData.isMyXFlow) || formData === null) && !validator.matches(value, regex.NAME_VALIDATION),
                message: "only-allowed-char-msg",
            },
        ],
        billingFirstName: [
            {
                method: (value, formData) => ((formData && formData.isMyXFlow) || formData === null) && !validator.matches(value, regex.NAME_VALIDATION),
                message: "only-allowed-char-msg",
            },
        ],
        lastName: [
            {
                method: (value, formData) => ((formData && !formData.isMyXFlow) || formData === null) && !validator.matches(value, regex.NAME_VALIDATION),
                message: "only-allowed-char-msg",
            },
        ],
        billingLastName: [
            {
                method: (value, formData) => ((formData && formData.isMyXFlow) || formData === null) && !validator.matches(value, regex.NAME_VALIDATION),
                message: "only-allowed-char-msg",
            },
        ],
        email: [
            {
                method: (value) => !validator.isEmail(value),
                message: "email-err-msg",
            },
        ],
        password: [
            {
                method: (value) => validator.matches(value, /\s/u),
                message: "pwd-validation-space",
            },
            {
                method: (value) => !validator.matches(value, /[a-z]/),
                message: "pwd-validation-lowercase",
            },
            {
                method: (value) => !validator.matches(value, /[A-Z]/),
                message: "pwd-validation-uppercase",
            },
            {
                method: (value) => validator.matches(value, / \d+ /),
                message: "pwd-err-msg-number",
            },
            {
                method: (value) => !validator.matches(value, /[0-9]/),
                message: "pwd-validation-number",
            },
            {
                method: (value) => !(value.length >= 8 && value.length <= 20),
                message: "pwd-validation-length",
            },
            {
                method: (value) => validator.matches(value, /['_\\\/"<>,]/),
                message: "pwd-validation-special-chars",
            },
        ],
        ccNumber: [
            {
                method: (value, formData, isCVViFrame) =>
                    isCVViFrame ? value.length < 20 : value.length > 19 || value.length < 13 || !validator.matches(value, /^[0-9]*$/u),
                message: "cc-error",
            },
        ],
        ccMonth: [
            {
                method: (value) => validateMonthAndYear(value, "month"),
                message: "valid-exp-month",
            },
        ],
        ccYear: [
            {
                method: (value) => validateMonthAndYear(value, "year"),
                message: "valid-exp-year",
            },
        ],
        ccCVV: [
            {
                method: (value, formData, isCVViFrame) =>
                    isCVViFrame ? !formData?.creditCardInfo?.cvv?.valid : !(value.length === 3 || value.length === 4) || !validator.matches(value, /^[0-9]*$/u),
                message: "cvv-err-msg",
            },
        ],

        billingPostalCode: [
            {
                method: (value) => !validator.matches(value, getZIPCodeValidation()) || value.length < 5 || value.length > 10,
                message: "zip-err-msg",
            },
        ],
        shippingPostalCode: [
            {
                method: (value) => !validator.matches(value, getZIPCodeValidation()) || value.length < 5 || value.length > 10,
                message: "zip-err-msg",
            },
        ],
        continuity: [
            {
                method: (value) => !value,
                message: "membership-err-msg",
            },
        ],
        legalTermsCondition: [
            {
                method: (value) => !value,
                message: "tc-err-msg",
            },
        ],
        shippingAddressLine1: [
            {
                method: (value) => !validator.matches(value, /^[A-Za-z0-9@.,#' _-]+$/u) || value.length > 30,
                message: "shipping-street-error-message",
            },
        ],
        shippingAddressLine2: [
            {
                method: (value) => (value.length > 0 ? !validator.matches(value, /^[A-Za-z0-9@.,#' _-]+$/u) || value.length > 30 : false),
                message: "shipping-street-error-message",
            },
        ],
        shippingCity: [
            {
                method: (value) => !validator.matches(value, regex.CITY_NAME_VALIDATION),
                message: "city-field-error-message",
            },
        ],
        coachId: [
            {
                method: (value) => value.length > 0 && isNaN(value) && !validator.isEmail(value) && value !== "leadwheel",
                message: "coach-referral-failed",
            },
        ],
        ccExpDate: [
            {
                method: (value, formData) => (formData ? ExpiryDate(formData["ccYear"], formData["ccMonth"]) : false),
                message: "card-expired",
            },
        ],
    };
};
const getValidationsForBlank = () => {
    return {
        firstName: [
            {
                method: (value, formData) => formData && !formData.isMyXFlow && validator.isEmpty(value),
                message: "first-name-empty-msg",
            },
        ],
        billingFirstName: [
            {
                method: (value, formData) => formData && formData.isMyXFlow && validator.isEmpty(value),
                message: "first-name-empty-msg",
            },
        ],
        lastName: [
            {
                method: (value, formData) => formData && !formData.isMyXFlow && validator.isEmpty(value),
                message: "last-name-empty-msg",
            },
        ],
        billingLastName: [
            {
                method: (value, formData) => formData && formData.isMyXFlow && validator.isEmpty(value),
                message: "last-name-empty-msg",
            },
        ],
        email: [
            {
                method: (value) => validator.isEmpty(value),
                message: "email-empty-msg",
            },
        ],
        password: [
            {
                method: (value) => validator.isEmpty(value),
                message: "pwd-empty-msg",
            },
        ],
        ccNumber: [
            {
                method: (value) => validator.isEmpty(value),
                message: "cc-error",
            },
        ],
        ccMonth: [
            {
                method: (value) => validator.isEmpty(value ? value : ""),
                message: "exp-date-required",
            },
        ],
        ccYear: [
            {
                method: (value) => validator.isEmpty(value ? value : ""),
                message: "exp-date-required",
            },
        ],
        ccCVV: [
            {
                method: (value, formData, isCVViFrame) => (isCVViFrame ? formData?.creditCardInfo?.cvv?.empty : validator.isEmpty(value)),
                message: "cvv-err-msg-req",
            },
        ],
        billingPostalCode: [
            {
                method: (value) => validator.isEmpty(value),
                message: "zip-err-msg",
            },
        ],
        shippingPostalCode: [
            {
                method: (value) => validator.isEmpty(value),
                message: "zip-err-msg",
            },
        ],
        shippingAddressLine1: [
            {
                method: (value) => validator.isEmpty(value),
                message: "shipping-street-error-message",
            },
        ],
        shippingCity: [
            {
                method: (value) => validator.isEmpty(value),
                message: "city-field-error-message",
            },
        ],
        shippingState: [
            {
                method: (value) => validator.isEmpty(value),
                message: "state-field-err-message",
            },
        ],
        coachId: [
            {
                method: (value, formData) => formData && formData.isMyXFlow && formData.isToValidateCoach && validator.isEmpty(value),
                message: "myx-coach-referral-failed",
            },
        ],
        coachConflict: [
            {
                method: (value) => validator.isEmpty(value),
                message: "coach-conflict-empty-msg",
            },
        ],
    };
};
